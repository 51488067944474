import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  Chip,
  IconButton,
  Modal,
  Checkbox,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProductWarranty } from "../../common/types/ProductWarrantyTypes";
import {
  LotusProErrors,
  iCleanMiniErrors,
} from "../../common/types/ProductWarrantyTypes";
import StyledButton from "../Tabs/StyledButton";

interface BulkUpdateErrorsModalProps {
  open: boolean;
  onClose: () => void;
  selectedWarranties: ProductWarranty[]; // Selected warranties should all have the same deviceModel
  bulkErrorForm: {
    returnReason: string;
    errorCode: { code: string; description: string }[];
    description?: string;
  };
  handleUpdateBulkError: (event: any) => void;
  handleConfirmBulkUpdate: () => void;
}

// Function to convert enums to key-value pairs
const enumToKeyValueArray = (enumObj: any) => {
  return Object.keys(enumObj).map((key) => ({
    code: key,
    description: enumObj[key],
  }));
};

const BulkUpdateErrorsModal: React.FC<BulkUpdateErrorsModalProps> = ({
  open,
  onClose,
  selectedWarranties,
  bulkErrorForm,
  handleUpdateBulkError,
  handleConfirmBulkUpdate,
}) => {
  const lotusProModels = [
    "Lotus PRO SAO Dispenser (LQFC225K)",
    "Lotus PRO SAO Dispenser (LQFC225K-FB)",
    "Lotus PRO SAO Dispenser (LQFC275-FB)",
    "Lotus PRO SAO Dispenser (LQFC275)",
    "LOTUS PRO",
  ];

  const iCleanMiniModels = [
    "iClean Mini (LQFC200)",
    "iClean PRO (LQFC300)",
    "SAO2GO (LQFC400)",
    "SAO2GO (LQFC401)",
    "iClean Flo (LQFC5XX)",
    "ICLEAN MINI",
  ];

  // Check if all warranties belong to the same category
  const isLotusProCategory = selectedWarranties.every((warranty) =>
    lotusProModels.includes(warranty.productSiteInformation.deviceModel)
  );
  const isICleanMiniCategory = selectedWarranties.every((warranty) =>
    iCleanMiniModels.includes(warranty.productSiteInformation.deviceModel)
  );

  let availableErrors: { code: string; description: string }[] = [];

  if (isLotusProCategory) {
    availableErrors = enumToKeyValueArray(LotusProErrors); // Show LotusPro errors
  } else if (isICleanMiniCategory) {
    availableErrors = enumToKeyValueArray(iCleanMiniErrors); // Show iCleanMini errors
  } else {
    availableErrors = []; // Show no options if not in the same category
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="bulk error update modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "70%", md: "40%" },
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "#f5f5f5",
          p: { xs: 2, sm: 3, md: 3 },
          fontFamily: "'Poppins', sans-serif",
          borderRadius: 1,
        }}
      >
        {/* Modal Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6">Bulk Update Warranty Errors</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "primary.main" }} />
          </IconButton>
        </Box>

        {/* Bulk Update Form */}
        <Paper
          elevation={1}
          sx={{ marginBottom: 2, backgroundColor: "#fff", p: 1 }}
        >
          <Grid container spacing={2}>
            {availableErrors.length === 0 && (
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Devices in the selected warranties must be in the same
                  category.
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Return Category</InputLabel>
                <Select
                  name="returnReason"
                  label="Return Category"
                  value={bulkErrorForm.returnReason || ""} // Ensure the value is initialized
                  onChange={(event) => handleUpdateBulkError(event)}
                >
                  <MenuItem value="Return & Repair">Return & Repair</MenuItem>
                  <MenuItem value="Low Flow">Low Flow</MenuItem>
                  <MenuItem value="Service">Service</MenuItem>
                  <MenuItem value="High Priority">High Priority</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                limitTags={2}
                options={availableErrors} // Dynamically load error codes based on device model
                getOptionLabel={(option) =>
                  option ? `${option.code} - ${option.description}` : ""
                }
                isOptionEqualToValue={(option, value) =>
                  option?.code === value?.code
                }
                value={bulkErrorForm.errorCode}
                onChange={(_event, value) => {
                  handleUpdateBulkError({
                    target: {
                      name: "errorCode",
                      value,
                    },
                  });
                }}
                renderTags={(selectedErrors, getTagProps) =>
                  selectedErrors.map((selectedError, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={
                        selectedError ? `${selectedError.description}` : ""
                      }
                    />
                  ))
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} style={{ marginRight: 8 }} />
                    {`${option.code} - ${option.description}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Error Code"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Confirm Button */}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            onClick={handleConfirmBulkUpdate}
            variantType="primary"
            size="large"
          >
            Confirm
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default BulkUpdateErrorsModal;
