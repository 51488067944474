import React, { useState, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Chart as ChartJS } from "chart.js/auto";
import useDeviceStore from "../../../store/useDeviceStore";

ChartJS.defaults.color = "#282828";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 0
});

interface DynamicBarGraphProps {
  onBarClick: (date: Date) => void;
  isGallons?: boolean;
}

const DynamicBarGraph: React.FC<DynamicBarGraphProps> = ({
  onBarClick,
  isGallons,
}) => {
  const theme = useTheme();
  const { dateFilter, setDateFilter, intervalFilter: interval, selectedIcleanDevices } = useDeviceStore();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(true);

  const chartData = useMemo(() => {
    const dataMap = new Map<string, number>();
    const endDate = dateFilter
      ? dayjs(dateFilter).endOf("day")
      : dayjs().endOf("day");
    let startDate: dayjs.Dayjs;

    if (interval === "daily") {
      startDate = endDate.subtract(7, "days");
      for (
        let date = startDate;
        date.isSameOrBefore(endDate);
        date = date.add(1, "day")
      ) {
        dataMap.set(date.toDate().toDateString(), 0);
      }
    } else if (interval === "weekly") {
      for (let i = 0; i < 7; i++) {
        const weekEnd = endDate.subtract(i, "week").endOf('week');
        const weekStart = weekEnd.startOf("week");
        const weekLabel = `${weekStart.format("DD/MMM")} - ${weekEnd.format("DD/MMM")}`;
        dataMap.set(weekLabel, 0);
      }
    } else if (interval === "monthly") {
      startDate = endDate.subtract(6, "months");
      for (
        let date = startDate;
        date.isSameOrBefore(endDate);
        date = date.add(1, "month")
      ) {
        dataMap.set(date.format("MMM YYYY"), 0);
      }
    }

    selectedIcleanDevices.forEach((device) => {
      device.deviceUsage.forEach((usage) => {
        const usageDate = dayjs(usage.uploadTimestamp);
        let labelKey: string | undefined;

        if (interval === "daily") {
          if (
            usageDate.isSameOrAfter(startDate) &&
            usageDate.isSameOrBefore(endDate)
          ) {
            labelKey = usageDate.toDate().toDateString();
          }
        } else if (interval === "weekly") {
          for (let i = 0; i < 7; i++) {
            const weekEnd = endDate.subtract(i, "week").endOf('week');
            const weekStart = weekEnd.startOf("week");
            const weekLabel = `${weekStart.format("DD/MMM")} - ${weekEnd.format("DD/MMM")}`;

            if (
              usageDate.isSameOrAfter(weekStart.startOf('day')) &&
              usageDate.isSameOrBefore(weekEnd.endOf('day'))
            ) {
              labelKey = weekLabel;
              break;
            }
          }
        } else if (interval === "monthly") {
          labelKey = usageDate.format("MMM YYYY");
        }
        if (labelKey) {
          const currentTotal = dataMap.get(labelKey) || 0;

          // If isGallons is true, convert liters to gallons, otherwise keep in liters
          const dispensedAmount = isGallons
            ? usage.litersDispensed * 0.264172 // Conversion from liters to gallons
            : usage.litersDispensed; // Keep in liters
          dataMap.set(labelKey, currentTotal + dispensedAmount);
        }
      });
    });

    let labels = Array.from(dataMap.keys());
    let data = Array.from(dataMap.values());

    // Sort labels and data by date for consistent display
    let sortedData: { label: string; data: number }[] = [];
    if (interval === "daily") {
      sortedData = labels
        .map((label, index) => ({ label, data: data[index] }))
        .sort((a, b) => dayjs(a.label).diff(dayjs(b.label)));
    } else if (interval === "weekly") {
      sortedData = labels
        .map((label, index) => ({ label, data: data[index] }))
        .sort((a, b) =>
          dayjs(a.label.split(" - ")[1], "DD/MMM").diff(
            dayjs(b.label.split(" - ")[1], "DD/MMM")
          )
        );
    } else if (interval === "monthly") {
      sortedData = labels
        .map((label, index) => ({ label, data: data[index] }))
        .sort((a, b) =>
          dayjs(a.label, "MMM YYYY").diff(dayjs(b.label, "MMM YYYY"))
        );
    }

    // Take the last 7 items only
    sortedData = sortedData.slice(-7);

    // Set loading to false after data is processed
    setTimeout(() => setIsLoading(false), 100);

    return {
      labels: sortedData.map((item) => item.label),
      datasets: [
        {
          label: isGallons ? "Gallons Dispensed" : "Liters Dispensed",
          data: sortedData.map((item) => item.data),
          backgroundColor: "rgba(30, 58, 138, 0.5)",
        },
      ],
    };
  }, [selectedIcleanDevices, interval, dateFilter, isGallons]);

  const handleDateChange = (direction: "forward" | "backward") => {
    let newDate = dayjs(dateFilter);

    if (interval === "daily") {
      newDate =
        direction === "forward"
          ? newDate.add(1, "day")
          : newDate.subtract(1, "day");
    } else if (interval === "weekly") {
      newDate =
        direction === "forward"
          ? newDate.add(1, "week")
          : newDate.subtract(1, "week");
    } else if (interval === "monthly") {
      newDate =
        direction === "forward"
          ? newDate.add(1, "month")
          : newDate.subtract(1, "month");
    }

    setDateFilter(newDate.toDate());
  };

  const handleBarClick = (event: any, elements: any[]) => {
    if (elements.length > 0 && chartData.labels) {
      // Ensure chartData.labels is not undefined
      const index = elements[0].index;
      const dateStr = chartData.labels[index];
      const date = dayjs(dateStr).toDate();
      onBarClick(date);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          p: 2,
          borderRadius: "8px",
          border: "1px solid #d1d1d1",
          backgroundColor: "background.paper",
          height: "250px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <CircularProgress
          size={40}
          thickness={4}
          sx={{
            color: "rgba(30, 58, 138, 0.5)",
            mb: 2
          }}
        />
        <Typography variant="body2" color="textSecondary">
          Loading usage data...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        height: "250px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 1,
          color: "#282828",
          fontWeight: "700",
          fontSize: "13px",
        }}
      >
        Overall Tracking Usage
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ minWidth: "12px" }}>
          {!isMobile && (
            <ArrowBackIosIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontSize: "1rem",
                fontWeight: "bold",
              }}
              onClick={() => handleDateChange("backward")}
            />
          )}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            height: "100%",
            width: "100%",
            minWidth: 0,
          }}
        >
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              onClick: handleBarClick,
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: isGallons ? "Gallons" : "Liters",
                  },
                },
              },
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 0,
                  bottom: 0,
                },
              },
            }}
          />
        </Box>
        <Box sx={{ minWidth: "12px" }}>
          {!isMobile && (
            <ArrowForwardIosIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                fontSize: "1rem",
                fontWeight: "bold",
              }}
              onClick={() => handleDateChange("forward")}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DynamicBarGraph;
