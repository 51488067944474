import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import {
  Box,
  Typography,
  Paper,
  Chip,
  ListItemIcon,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  styled,
  SelectChangeEvent,
  InputAdornment,
  Modal,
  Grid,
  Snackbar,
  Alert,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  fetchDeviceArrivals,
  updateDeviceArrivalStatus,
} from "../../services/product-warranty.service";
import { fetchAllProductWarranties } from "../../services/product-warranty.service";
import useAuthStore from "../../store/authStore";
import { StyledTextFieldNew } from "../Forms/StyledTextFieldNew";
import DescriptionIcon from "@mui/icons-material/Description";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import { ProductWarranty } from "../../common/types/ProductWarrantyTypes";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

const IconWrapper = styled(ListItemIcon)({
  justifyContent: "center",
  minWidth: "30px",
  "& svg": {
    fill: "#9e9e9e",
    stroke: "black",
    strokeWidth: "1px",
  },
  "&:hover": {
    color: "primary.main",
    bgColor: "primary.main",
  },
});

interface DeviceArrivalData {
  warrantyClaim: string;
  status: string;
  arrivalDate: string;
  updatedAt: string;
  shippingDetails?: string;
  detailedIssue?: string;
  photo?: string;
  _id: string;
}

const DeviceArrivals: React.FC<any> = ({ devices }) => {
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [arrivals, setArrivals] = useState<DeviceArrivalData[]>([]);
  const [warrantiesMap, setWarrantiesMap] = useState<
    Map<string, ProductWarranty>
  >(new Map());
  const [selectedArrival, setSelectedArrival] =
    useState<DeviceArrivalData | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [comment, setComment] = useState("");
  const { user, userRole } = useAuthStore();
  const theme = useTheme();

  useEffect(() => {
    if (user && userRole) {
      const fetchData = async () => {
        try {
          const [arrivalsResponse, warrantiesResponse] = await Promise.all([
            fetchDeviceArrivals(user.email, userRole),
            fetchAllProductWarranties(),
          ]);

          setArrivals(arrivalsResponse);
          const warrantiesMap = new Map(
            warrantiesResponse.map((warranty: ProductWarranty) => [
              warranty._id,
              warranty,
            ])
          );
          setWarrantiesMap(warrantiesMap);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [user, userRole]);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenModal = (arrival: DeviceArrivalData) => {
    setSelectedArrival(arrival);
    setSelectedStatus(arrival.status);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedArrival(null);
  };

  const handleConfirm = async (id: string, newStatus: string) => {
    try {
      await updateDeviceArrivalStatus(id, newStatus);
      setArrivals((prevArrivals) =>
        prevArrivals.map((arrival) =>
          arrival._id === id ? { ...arrival, status: newStatus } : arrival
        )
      );
      setSnackbar({
        open: true,
        message: "Device arrival status updated successfully",
        severity: "success",
      });
      handleCloseModal();
    } catch (error) {
      console.error("Failed to update device arrival status:", error);
      setSnackbar({
        open: true,
        message: "Failed to update device arrival status",
        severity: "error",
      });
    }
    setComment("");
  };

  const [selectedStatus, setSelectedStatus] = useState(selectedArrival?.status);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  };

  const filteredArrivals = useMemo(() => {
    return arrivals.filter((arrival) => {
      const warranty = warrantiesMap.get(arrival.warrantyClaim);
      return !!warranty; // Only filter out arrivals without warranties
    });
  }, [arrivals, warrantiesMap]);

  // Modify getDisplayedArrivals to use filteredArrivals instead of sortedArrivals
  const getDisplayedArrivals = (): DeviceArrivalData[] => {
    switch (value) {
      case 1:
        return filteredArrivals.filter((arrival) => arrival.status === "ARRIVED");
      case 2:
        return filteredArrivals.filter(
          (arrival) =>
            arrival.status === "UNKNOWN" || arrival.status === "TRANSIT"
        );
      case 3:
        return filteredArrivals.filter((arrival) => arrival.status === "SHIPPED");
      default:
        return filteredArrivals;
    }
  };

  const displayedArrivals = getDisplayedArrivals();

  const getSerialNumber = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.productSiteInformation.serialNumber : "Unknown";
  };

  const getDistributorEmail = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.distributorInformation.email : "Unknown";
  };

  const getCompany = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.productSiteInformation.company : "Unknown";
  };

  const getDeviceModel = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.productSiteInformation.deviceModel : "Unknown";
  };

  const getReturnReason = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    return warranty ? warranty.faultDescription.returnReason : "Unknown";
  };

  const getDeviceError = (warrantyClaimId: string) => {
    const warranty = warrantiesMap.get(warrantyClaimId);
    if (warranty && warranty.faultDescription?.errorCode) {
      return Array.isArray(warranty.faultDescription.errorCode)
        ? warranty.faultDescription.errorCode
          .map((error) => error.description) // Extract descriptions
          .join(", ") // Join them into a comma-separated string
        : "Unknown";
    }
    return "Unknown";
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "Serial No.",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => getSerialNumber(params.row.warrantyClaim),
    },
    {
      field: "distributorEmail",
      headerName: "Distributor Email",
      flex: 1.5,
      minWidth: 200,
      renderCell: (params) => getDistributorEmail(params.row.warrantyClaim),
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => getCompany(params.row.warrantyClaim),
    },
    {
      field: "deviceModel",
      headerName: "Device Model",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => getDeviceModel(params.row.warrantyClaim),
    },
    {
      field: "returnReason",
      headerName: "Return Reason",
      flex: 1.5,
      minWidth: 250,
      renderCell: (params) =>
        getDeviceError(params.row.warrantyClaim) ||
        getReturnReason(params.row.warrantyClaim),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => (
        <Chip
          label={params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1).toLowerCase()}
          size="small"
          color={
            params.row.status === "SHIPPED"
              ? "success"
              : params.row.status === "ARRIVED"
                ? "info"
                : params.row.status === "TRANSIT"
                  ? "warning"
                  : "error"
          }
        />
      ),
    },
    {
      field: "arrivalDate",
      headerName: "Arrival Date",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => new Date(params.row.arrivalDate).toLocaleDateString(),
    },
    {
      field: "shippingDetails",
      headerName: "Shipping Details",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => params.row.shippingDetails,
    },
    {
      field: "updatedAt",
      headerName: "Last Update",
      flex: 1,
      minWidth: 125,
      renderCell: (params) =>
        new Date(params.row.updatedAt).toLocaleDateString(),
    },
  ];

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
              p: 0.5,
            }}
          >
            <Typography variant="h5">Manage Device Arrivals</Typography>
          </Box>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="device arrivals tabs"
          >
            <PlainTab label="All" />
            <PlainTab label="Arrived" />
            <PlainTab label="Pending" />
            <PlainTab label="Shipped" />
          </StyledTabs>
          <Box sx={{ p: 0 }}>
            <Box sx={{ mt: 2 }}>
              <DataGrid
                rows={displayedArrivals}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700",
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
                onRowClick={(params) =>
                  userRole !== "Tester" && userRole !== "Production"
                    ? handleOpenModal(params.row)
                    : undefined
                }
                loading={!arrivals.length}
              />
            </Box>
            <Box>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "80%", sm: "70%", md: "40%" },
                    maxHeight: "90vh",
                    overflowY: "auto",
                    bgcolor: "#f5f5f5",
                    p: { xs: 2, sm: 3, md: 3 },
                    fontFamily: "'Poppins', sans-serif",
                    borderRadius: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        color: "grey.800",
                        textAlign: "center",
                      }}
                    >
                      Arrival Details
                    </Typography>
                    <StyledIconButton
                      variantType="transparent"
                      iconColor={theme.palette.primary.main}
                      onClick={handleCloseModal}
                    >
                      <CloseIcon />
                    </StyledIconButton>
                  </Box>

                  {selectedArrival && (
                    <Paper
                      elevation={1}
                      sx={{ marginBottom: 2, backgroundColor: "#fff", p: 1 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body1" sx={{ fontWeight: 500 }}>
                            Device Details:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Serial Number"
                            value={getSerialNumber(
                              selectedArrival.warrantyClaim
                            )}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                          />
                          <TextField
                            label="Company"
                            value={getCompany(selectedArrival.warrantyClaim)}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Distributor"
                            value={getDistributorEmail(
                              selectedArrival.warrantyClaim
                            )}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                          />
                          <TextField
                            label="Device Error"
                            value={
                              getDeviceError(selectedArrival.warrantyClaim) ||
                              getReturnReason(selectedArrival.warrantyClaim)
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                              labelId="status-label"
                              label="Status"
                              name="status"
                              value={selectedStatus}
                              onChange={handleStatusChange}
                            >
                              <MenuItem value="UNKNOWN">Unknown</MenuItem>
                              <MenuItem value="SHIPPED">Shipped</MenuItem>
                              <MenuItem value="ARRIVED">Arrived</MenuItem>
                              <MenuItem value="TRANSIT">In Transit</MenuItem>
                              <MenuItem value="OTHER">Other</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Device Model"
                            value={getDeviceModel(
                              selectedArrival.warrantyClaim
                            )}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextFieldNew
                            label="Comment"
                            name="Comment"
                            onChange={handleCommentChange}
                            value={comment}
                            required
                            fullWidth
                            margin="normal"
                            multiline
                            rows={2}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconWrapper>
                                    <DescriptionIcon />
                                  </IconWrapper>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  )}

                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <StyledButton
                      onClick={() => {
                        if (selectedArrival && selectedStatus) {
                          handleConfirm(selectedArrival._id, selectedStatus);
                        }
                      }}
                      variantType="primary"
                      size="large"
                    >
                      Confirm
                    </StyledButton>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Positioning at the top center
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeviceArrivals;
