import React, { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

const ManageUsers: React.FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}>
        <Typography variant="h5">Manage Users</Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} aria-label="test status tabs">
        <Tab label="Admins" />
        <Tab label="Distributors" />
        <Tab label="Customers" />
        <Tab label="Customer Service" />

        <Tab label="Testers" />
        <Tab label="Blacklisted" />
      </Tabs>
    </Box>
  );
};

export default ManageUsers;
