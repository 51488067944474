import { Box, Grid, Tooltip, Chip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useDeviceStore from "../../store/useDeviceStore";
import CartridgeIndicator from "./CartridgeIndicator";
import dayjs from "dayjs";

const CartridgeDashboard = () => {
  const { allDevices } = useDeviceStore();

  const getStatusLabel = (status: string): string => {
    if (!status) return "N/A";
    switch (status.toUpperCase()) {
      case "ONLINE":
        return "ON";
      case "OFFLINE":
        return "OFF";
      default:
        return "N/A";
    }
  };

  const columns: GridColDef[] = [
    {
      field: "deviceId",
      headerName: "Device ID",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => params.row.deviceInformation.deviceId
    },
    {
      field: "deviceName",
      headerName: "Device Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => params.row.deviceInformation.deviceName
    },
    {
      field: "cartridgeType",
      headerName: "Cartridge Type",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => params.row.deviceInformation.currentCartridgeType
    },
    {
      field: "cartridgeLife",
      headerName: "Cartridge Life",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <CartridgeIndicator
              cyclesRemaining={
                params.row.deviceUsage.length > 0
                  ? params.row.deviceUsage[
                    params.row.deviceUsage.length - 1
                  ]?.cyclesRemaining || 0
                  : 0
              }
              currentCartridgeType={
                params.row.deviceInformation.currentCartridgeType
              }
              // isGallons={isGallons || false}
              isGallons={false}
            />
          </Box>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const statusLabel = getStatusLabel(params.row.pingStatus.status);

        return (
          <Tooltip
            title={
              <Typography>
                Last Ping:{" "}
                {dayjs(params.row.pingStatus.timestamp).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </Typography>
            }
          >
            <Chip
              label={statusLabel}
              size="small"
              sx={{
                color: "white",
                backgroundColor: (theme) => {
                  switch (statusLabel) {
                    case "ON":
                      return theme.palette.success.main;
                    case "OFF":
                      return theme.palette.error.main;
                    default:
                      return theme.palette.grey[500];
                  }
                },
                "& .MuiChip-label": {
                  padding: "0 8px",
                },
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        {/* Right side - Devices Grid */}
        <Grid item xs={12} md={6}>
          <DataGrid
            rows={allDevices}
            columns={columns}
            getRowId={(row) => row._id}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "700",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection
            disableRowSelectionOnClick
          // onRowSelectionModelChange={(newSelection) => {
          //   setSelected(newSelection.map((id) => String(id)));
          // }}
          // rowSelectionModel={selected}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartridgeDashboard;
