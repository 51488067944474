import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

type deviceToCustomersType = {
  deviceId: string;
  customers: string[];
};

type customerToDevicesType = {
  customer: string;
  deviceIds: string[];
};

// Assign Customers to a Device
export const deviceToCustomers = async (payload: deviceToCustomersType) => {
  try {
    const response = await axios.post(
      `${API_URL}/bulk/device-to-customers`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

// Assign Devices to a Customer
export const customerToDevices = async (payload: customerToDevicesType) => {
  try {
    const response = await axios.post(
      `${API_URL}/bulk/customer-to-devices`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};
