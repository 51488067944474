import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import useAuthStore from "../../store/authStore";
import {
  fetchDistributors,
  addSubDistributorsToDistributor,
} from "../../services/distributor.service";
import { Distributor } from "../../store/useDistributor";
import StyledButton from "../Tabs/StyledButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (message: string) => void;
  onSnackbarChange: (snackbarData: {
    open: boolean;
    message: string;
    severity: "success" | "error";
  }) => void; // Add this prop to handle snackbar changes
}

interface SubDistributorPayload {
  firstName: string;
  lastName: string;
  email: string;
}

const CreateSubDistributorModal: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onError,
  onSnackbarChange,
}) => {
  const { user, userRole } = useAuthStore();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const [selectedDistributor, setSelectedDistributor] = useState<string>("");
  const [subDistributorData, setSubDistributorData] =
    useState<SubDistributorPayload>({
      firstName: "",
      lastName: "",
      email: "",
    });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userRole === "Admin" || userRole === "SuperAdmin") {
      fetchDistributors()
        .then(setDistributors)
        .catch(() => onError("Failed to fetch distributors."));
    } else if (user && userRole === "Distributor") {
      setSelectedDistributor(user.email); // Automatically set for distributors
    }
  }, [user, userRole, onError]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSubDistributorData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      !subDistributorData.email ||
      !subDistributorData.firstName ||
      !subDistributorData.lastName
    ) {
      setError("All fields are required.");
      return;
    }

    try {
      await addSubDistributorsToDistributor(
        subDistributorData,
        selectedDistributor
      );
      onSuccess();
      onClose();
      // Show success message
      onSnackbarChange({
        open: true,
        message: "Sub-distributor added successfully",
        severity: "success",
      });
    } catch (error: any) {
      onError(`Error: ${error.response?.data.message || error.message}`);
      onSnackbarChange({
        open: true,
        message: `Error: ${error.response?.data.message || error.message}`,
        severity: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Sub-Distributor</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Default Password: <strong>Password@321</strong>
        </Typography>
        {(userRole === "Admin" || userRole === "SuperAdmin") && (
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel>Distributor</InputLabel>
            <Select
              value={selectedDistributor}
              label="Distributor"
              onChange={(e) => setSelectedDistributor(e.target.value)}
            >
              {distributors.map((distributor) => (
                <MenuItem key={distributor._id} value={distributor._id}>
                  {distributor.firstName} {distributor.lastName} - (
                  {distributor.email})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}

        <TextField
          margin="dense"
          label="First Name"
          name="firstName"
          type="text"
          fullWidth
          variant="outlined"
          value={subDistributorData.firstName}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          label="Last Name"
          name="lastName"
          type="text"
          fullWidth
          variant="outlined"
          value={subDistributorData.lastName}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          label="Email"
          name="email"
          type="email"
          fullWidth
          variant="outlined"
          value={subDistributorData.email}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose}>Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Add Sub-Distributor
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSubDistributorModal;
