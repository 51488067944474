import React, { useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";
import { StyledTabs } from "../components/Tabs/IconTab";
import PlainTab from "../components/Tabs/PlainTab";
import ProfileDetails from "../components/Account/ProfileDetails";
import ChangePassword from "../components/Account/ChangePassword";
import Settings from "../components/Account/ProfileSettings";

export const ProfilePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedProfilePageTab", newValue.toString());
  };

  return (
    <Box p={0}>
      <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "90%", md: "90%" },
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Manage Your Account
          </Typography>
          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
            variant="scrollable" // Enable scrolling
            scrollButtons="auto" // Enable auto scrolling
          >
            <PlainTab label="Account" />
            <PlainTab label="Change Password" />
            <PlainTab label="Settings" />
          </StyledTabs>
          <Box sx={{ p: 2 }}>
            {selectedTab === 0 && <ProfileDetails />}
            {selectedTab === 1 && <ChangePassword />}
            {selectedTab === 2 && <Settings />}
          </Box>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
