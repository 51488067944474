import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Paper,
  Tooltip,
  Chip,
  Typography,
  MenuItem,
  Alert,
  Snackbar,
  AlertColor,
  useTheme,
  Menu,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCircle,
  Height,
} from "@mui/icons-material";
import {
  SalesData,
  AccountsData,
} from "../../common/types/DistributorSalesTypes";
import {
  fetchSalesByDistributorEmail,
  fetchAccountsByDistributorEmail,
  deleteSalesReport,
} from "../../services/distributor.service";
import useAuthStore from "../../store/authStore";
import AddSalesReportModal from "./AddSalesModal";
import UploadCsvModal from "./UploadSalesCsvModal";
import UploadFile from "@mui/icons-material/UploadFile";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import GenerateSalesReport from "./GenerateSalesReport";
import SalesReportHistory from "./SalesReportHistory";
import EditSalesModal from "./EditSalesModal";

const productTypeColors: { [key: string]: string } = {
  NONE: "#e0e0e0",
  "SAO-4": "#d1c4e9",
  "SAO-24": "#c5cae9",
  "lotus PRO": "#bbdefb",
  "iClean mini": "#b2dfdb",
  "iClean PRO": "#b3e5fc",
  "iClean FLO3": "#b2ebf2",
};

const accountTypeColors: { [key: string]: string } = {
  ISS: "#0057A7",
  Sodexo: "#ef5350",
  FoodBuy: "#6d4c41",
  CushmanWakefield: "#ab47bc",
  Frasers: "#827717",
  WeWork: "#2e7d32",
  JLL: "#00695c",
  McDonalds: "#f06292",
  Accor: "#607d8b",
  NonGlobalAccount: "#424242",
};

const DistributorSalesReporting: React.FC<{}> = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const [sales, setSales] = useState<SalesData[]>([]);
  const [selectedSales, setSelectedSales] = useState<SalesData | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedDeleteReport, setSelectedDeleteReport] =
    useState<SalesData | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isMultipleEntries, setIsMultipleEntries] = useState(false);
  const [accounts, setAccounts] = useState<AccountsData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({
    open: false,
    message: "",
    severity: undefined,
  });

  const { user, userRole } = useAuthStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleSingleEntry = () => {
    handleClose();
    setIsMultipleEntries(false);
    setOpenModal(true); // Logic for adding a single entry
  };

  const handleMultipleEntries = () => {
    handleClose();
    setIsMultipleEntries(true);
    setOpenModal(true); // Logic for adding multiple entries
  };

  const fetchAccounts = useCallback(async () => {
    try {
      const fetchedAccounts = await fetchAccountsByDistributorEmail(
        user?.email || ""
      );
      // remove duplicate accounts
      const uniqueAccounts = fetchedAccounts.filter(
        (account, index, self) =>
          index === self.findIndex((t) => t.accountName === account.accountName)
      );
      setAccounts(uniqueAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  }, [user?.email]);

  const fetchSales = useCallback(async () => {
    if (user) {
      const salesData = await fetchSalesByDistributorEmail(
        user.email,
        userRole || ""
      );
      setSales(salesData); // Correctly setting the sales data
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchSales();
      fetchAccounts();
    }
  }, [user, fetchSales, fetchAccounts]);

  const handleAddReportSuccess = async () => {
    try {
      // Re-fetch the sales data after adding a new report
      await fetchSales(); // This function should fetch the latest data from your API
      await fetchAccounts();
      setSnackbar({
        open: true,
        message: "Report added successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleBulkAddReportSuccess = async () => {
    try {
      // Re-fetch the sales data after adding a new report
      await fetchSales(); // This function should fetch the latest data from your API
      setSnackbar({
        open: true,
        message: "Reports added successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleEditReportSuccess = async () => {
    try {
      // Re-fetch the sales data after editing a report
      await fetchSales(); // This function should fetch the latest data from your API
      await fetchAccounts();
      setSnackbar({
        open: true,
        message: "Report edited successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Failed to fetch updated sales data:", error);
    }
  };

  const handleAddReportError = (message: string) => {
    console.error("Failed to add report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const handleEditReportError = (message: string) => {
    console.error("Failed to edit report:", message);
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const handleDeleteReport = async () => {
    if (selectedDeleteReport?._id) {
      try {
        await deleteSalesReport(selectedDeleteReport._id);
        setSnackbar({
          open: true,
          message: "Report deleted successfully",
          severity: "success",
        });
        await fetchSales(); // Refresh the sales data
      } catch (error) {
        console.error("Failed to delete report:", error);
        setSnackbar({
          open: true,
          message: "Failed to delete report",
          severity: "error",
        });
      } finally {
        setOpenDeleteDialog(false);
        setSelectedDeleteReport(null);
      }
    }
  };

  const handleDeleteClick = (report: SalesData) => {
    setSelectedDeleteReport(report);
    setOpenDeleteDialog(true);
  };

  const handleSubmit = () => {
    setSnackbar({
      open: true,
      message: "Report generated successfully",
      severity: "success",
    });
  };

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const columns: GridColDef[] = [
    {
      field: "accountType",
      headerName: "Account Type",
      flex: 1,
      minWidth: 175,
      valueGetter: (_value, row) => row.account.accountType,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          style={{
            backgroundColor: accountTypeColors[params.value],
            color: "white",
          }}
        />
      ),
    },
    {
      field: "accountName",
      headerName: "Account Name",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.account.accountName,
    },
    {
      field: "accountEmail",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.account.accountEmail,
    },
    {
      field: "accountPhone",
      headerName: "Phone",
      flex: 1,
      minWidth: 125,
      valueGetter: (_value, row) => row.account.accountPhone,
    },
    {
      field: "accountAddress",
      headerName: "Address",
      flex: 1,
      minWidth: 150,
      valueGetter: (_value, row) => row.account.accountAddress,
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      minWidth: 125,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          sx={{
            fontSize: 14,
            backgroundColor: productTypeColors[params.value] || "#e0e0e0",
          }}
        />
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1.5,
      minWidth: 200,
      valueFormatter: (value) => new Date(value).toLocaleString(),
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 125,
    },
    // Conditionally add distributorEmail column for Admin users
    ...(userRole === "Admin"
      ? [
          {
            field: "distributorEmail",
            headerName: "Distributor Email",
            flex: 1,
            minWidth: 200,
            valueGetter: (_value: any, row: SalesData) => row.distributorEmail,
          },
        ]
      : []),

    {
      field: "actions",
      headerName: "Actions",
      flex: 1.5,
      minWidth: 125,
      sortable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <StyledIconButton
              variantType="transparent"
              onClick={() => {
                setSelectedSales(params.row);
                setOpenEditModal(true);
              }}
            >
              <EditIcon />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <StyledIconButton
              variantType="transparent"
              onClick={() => handleDeleteClick(params.row)}
            >
              <DeleteIcon />
            </StyledIconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredSales = useMemo(() => {
    return sales.filter(
      (sale) =>
        sale.account.accountName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountType
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountEmail
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountPhone
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.accountAddress
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.account.siteContact
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        sale.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.notes.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.distributorEmail.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sales, searchTerm]);

  return (
    <Box sx={{ p: 0 }}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Typography sx={{ mb: 2 }} variant="h6">
            Distributor Monthly Sales Reporting
          </Typography>
          {user?.role === "Admin" && (
            <StyledTabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Profile tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              <PlainTab label="Sales" />
              <PlainTab label="Generate Report" />
              <PlainTab label="History" />
            </StyledTabs>
          )}
          {selectedTab === 0 && (
            <Box>
              <Box
                sx={{
                  display: isMobile ? "flex-column" : "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  p: 0,
                  mt: 2,
                }}
              >
                {/* Search input field */}
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{
                    width: isMobile ? "100%" : "300px",
                    height: "28px",
                    borderRadius: 1,
                    backgroundColor: "#f5f5f5",
                    marginBottom: isMobile ? 2 : 0,
                  }}
                />
                <Box
                  sx={{
                    display: isMobile ? "flex-column" : "flex",
                    alignItems: "flex-end",
                    gap: isMobile ? 2.5 : 1.5,
                    mb: 1.5,
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      height: "30px",
                      border: "1px solid #e0e0e0",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      Total Sales :{" "}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {" "}
                      {sales.length}
                    </Typography>
                  </Paper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      gap: isMobile ? 2.5 : 1.5,
                      mt: 1.5,
                    }}
                  >
                    <Tooltip title="Add New Sales" placement="bottom">
                      <>
                        <StyledButton
                          icon={<AddCircle />}
                          iconColor={theme.palette.primary.main}
                          onClick={handleClick}
                        >
                          Add Sales
                        </StyledButton>
                        {/* Dropdown Menu */}
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={handleSingleEntry}>
                            Add a Single Entry
                          </MenuItem>
                          <MenuItem onClick={handleMultipleEntries}>
                            Add Multiple Entries
                          </MenuItem>
                        </Menu>
                      </>
                    </Tooltip>
                    <Tooltip title="Upload Sales Report" placement="bottom">
                      <StyledButton
                        icon={<UploadFile />}
                        iconColor={theme.palette.primary.main}
                        onClick={() => setOpenUploadModal(true)}
                      >
                        Upload CSV
                      </StyledButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Box>
                <DataGrid
                  rows={filteredSales}
                  columns={columns}
                  getRowId={(row) => row._id}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "700",
                    },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newSelection) => {
                    setSelected(newSelection.map((id) => String(id)));
                  }}
                  rowSelectionModel={selected}
                />
              </Box>
            </Box>
          )}

          {selectedTab === 1 && user?.role === "Admin" && (
            <GenerateSalesReport sales={sales} onSubmit={handleSubmit} />
          )}

          {selectedTab === 2 && user?.role === "Admin" && (
            <SalesReportHistory />
          )}
        </Box>
      </Box>
      {/* Add Sales Report Modal */}
      <AddSalesReportModal
        open={openModal}
        isMultipleEntries={isMultipleEntries}
        onClose={() => setOpenModal(false)}
        onSuccess={handleAddReportSuccess}
        onError={handleAddReportError}
        accounts={accounts}
        distributorEmail={user?.email || ""}
      />{" "}
      {/* Edit Sales Report Modal */}
      {selectedSales && (
        <EditSalesModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          onSuccess={handleEditReportSuccess}
          onError={handleEditReportError}
          salesReport={selectedSales}
          accounts={accounts}
        />
      )}
      {/* Upload Sales Report Modal */}
      <UploadCsvModal
        open={openUploadModal} // control the modal open state
        onClose={() => setOpenUploadModal(false)}
        onUploadSuccess={(_csvData: SalesData[], errorMessage?: string) => {
          if (errorMessage) {
            setSnackbar({
              open: true,
              message: errorMessage,
              severity: "error",
            });
          } else {
            handleBulkAddReportSuccess();
          }
        }}
        distributorEmail={user?.email || ""}
      />
      {/* Delete Sales Report Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <Typography sx={{ px: 3, pt: 1 }}>
          Are you sure you want to delete this report?
        </Typography>
        <DialogActions>
          <StyledButton
            onClick={() => setOpenDeleteDialog(false)}
            variantType="outline-primary"
          >
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteReport} variantType="destructive">
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DistributorSalesReporting;
