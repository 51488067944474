import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import utc from "dayjs/plugin/utc";
import {
  Box,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import { ICleanLinesDevice } from "../../../../common/types/DeviceTypes";
import { Chart as ChartJS } from "chart.js/auto";

ChartJS.defaults.font.family = `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`;
ChartJS.defaults.font.size = 11;
ChartJS.defaults.color = "#282828";
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(weekOfYear);

interface iCleanLinesMetricGraphProps {
  selectedDevices: ICleanLinesDevice[];
}

const ICleanLinesMetricGraph: React.FC<iCleanLinesMetricGraphProps> = ({
  selectedDevices,
}) => {
  const theme = useTheme();

  // Extract the last 20 entries from the selected device's deviceUsage
  const processedData = useMemo(() => {
    if (selectedDevices.length === 0 || !selectedDevices[0].deviceUsage) {
      return {
        metrics: {
          tds: { T1: [], T2: [] },
          pressure: { P1: [], P2: [] },
          flowRate: [],
        },
        dateLabels: [],
      };
    }

    const deviceUsage = selectedDevices[0].deviceUsage.slice(-20); // Last 20 entries

    const metrics: {
      tds: { T1: number[]; T2: number[] };
      pressure: { P1: number[]; P2: number[] };
      flowRate: number[];
    } = {
      tds: { T1: [], T2: [] },
      pressure: { P1: [], P2: [] },
      flowRate: [],
    };

    // Format time in UTC or specific timezone
    const dateLabels: string[] = deviceUsage.map(
      (usage) =>
        dayjs(usage.time) // Parse time
          .utc() // Keep in UTC (if required)
          // .tz("America/New_York") // Uncomment to convert to a specific timezone (e.g., Eastern Time)
          .format("HH:mm:ss") // Display time in HH:mm:ss format
    );

    deviceUsage.forEach((usage) => {
      metrics.tds.T1.push(usage.T1 || 0);
      metrics.tds.T2.push(usage.T2 || 0);
      metrics.pressure.P1.push(usage.P1 || 0);
      metrics.pressure.P2.push(usage.P2 || 0);
      metrics.flowRate.push(usage.flowRate || 0);
    });

    return { metrics, dateLabels };
  }, [selectedDevices]);

  const { metrics, dateLabels } = processedData;

  // Calculate dynamic y-axis range for TDS
  const tdsRange = useMemo(() => {
    const allTdsValues = [...metrics.tds.T1, ...metrics.tds.T2];
    const min = Math.min(...allTdsValues) - 2; // Add a small margin
    const max = Math.max(...allTdsValues) + 2;
    return { min, max };
  }, [metrics.tds]);

  const createChartData = (
    metricData: { [key: string]: number[] } | number[],
    labels: string[],
    keys?: string[]
  ) => ({
    labels,
    datasets: keys
      ? keys.map((key, index) => ({
        label: key,
        data: (metricData as { [key: string]: number[] })[key],
        borderColor:
          index === 0
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        backgroundColor: "transparent",
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
      }))
      : [
        {
          label: "Flow Rate",
          data: metricData as number[],
          borderColor: theme.palette.primary.main,
          backgroundColor: "transparent",
          tension: 0.4,
          borderWidth: 2,
          pointRadius: 3,
        },
      ],
  });

  const chartOptions = (
    yAxisLabel: string,
    showLegend: boolean = false,
    dynamicScale?: { min: number; max: number }
  ) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: showLegend,
        position: "top" as const,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: yAxisLabel,
        },
        min: dynamicScale?.min,
        max: dynamicScale?.max,
        ticks: {
          stepSize: dynamicScale
            ? (dynamicScale.max - dynamicScale.min) / 5
            : undefined,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
    },
  });

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        height: "auto",
        minHeight: "380px",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h6"
        sx={{ textAlign: "center", fontWeight: "700", fontSize: "13px" }}
      >
        System Metrics (Last 20 Entries)
      </Typography>

      <Divider />

      {/* TDS Graph */}
      {metrics.tds.T1.length > 0 && metrics.tds.T2.length > 0 && (
        <Box sx={{ height: "100px", mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            Total Dissolved Solids (TDS)
          </Typography>
          <Line
            data={createChartData(metrics.tds, dateLabels, ["T1", "T2"])}
            options={chartOptions("PPM", false, tdsRange)}
          />
        </Box>
      )}

      {/* Pressure Graph */}
      {metrics.pressure.P1.length > 0 && metrics.pressure.P2.length > 0 && (
        <Box sx={{ height: "100px", mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            Pressure
          </Typography>
          <Line
            data={createChartData(metrics.pressure, dateLabels, ["P1", "P2"])}
            options={chartOptions("PSI")}
          />
        </Box>
      )}

      {/* Flow Rate Graph */}
      {metrics.flowRate.length > 0 && (
        <Box sx={{ height: "100px", mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
            Flow Rate
          </Typography>
          <Line
            data={createChartData(metrics.flowRate, dateLabels)}
            options={chartOptions("GPM")}
          />
        </Box>
      )}
    </Box>
  );
};

export default ICleanLinesMetricGraph;
