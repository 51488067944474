import React, { useState } from "react";
import { Box, TextField, Paper } from "@mui/material";
import { styled } from "@mui/system";
import StyledButton from "../Tabs/StyledButton";

const ChatBox: React.FC = () => {
  const [messages, setMessages] = useState<{ sender: string; text: string }[]>(
    []
  );
  const [input, setInput] = useState("");

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { sender: "User", text: input }]);
      setInput("");

      // Simulate bot response (replace this with actual API call)
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "AI", text: "This is a response from the bot." },
        ]);
      }, 1000);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        maxWidth: 600,
        margin: "auto",
        height: 500,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}>
        {messages.map((msg, index) => (
          <MessageContainer key={index} sender={msg.sender}>
            <MessageBubble sender={msg.sender}>{msg.text}</MessageBubble>
          </MessageContainer>
        ))}
      </Box>
      <Box sx={{ display: "flex" }}>
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder="Type your message here..."
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
          }}
        />
        <StyledButton
          variantType="primary"
          size="large"
          onClick={handleSend}
          sx={{ ml: 2 }}
        >
          Send
        </StyledButton>
      </Box>
    </Paper>
  );
};

// Styled component for the message container
const MessageContainer = styled("div")<{ sender: string }>(({ sender }) => ({
  display: "flex",
  justifyContent: sender === "User" ? "flex-start" : "flex-end",
  marginBottom: "10px",
}));

// Styled component for the message bubble
const MessageBubble = styled("div")<{ sender: string }>(({ sender }) => ({
  maxWidth: "70%",
  padding: "10px 15px",
  borderRadius: "20px",
  backgroundColor: sender === "User" ? "#d1e7dd" : "#cfe2ff",
  color: sender === "User" ? "#0f5132" : "#084298",
  textAlign: sender === "User" ? "left" : "right",
}));

export default ChatBox;
