import { useMemo } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import dayjs from "dayjs";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function formatNumber(value: number, decimals: number = 2): string {
  return value.toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: { display: false },
    y: { display: false },
  },
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          const value = context.parsed.y;
          const isGallons = context.chart.options.plugins.tooltip.isGallons;
          const unit = isGallons ? "Gal" : "L";
          const formattedValue = isGallons
            ? (value / 3.78541).toFixed(2)
            : value.toFixed(2);
          return `${formattedValue} ${unit}`;
        },
        title: function (tooltipItems: any[]) {
          return dayjs(tooltipItems[0].label).format("MMM D, YYYY");
        },
      },
    },
  },
};

type TotalUsageCardProps = {
  selectedDevices: ICleanDevice[];
  color: string;
  isGallons?: boolean;
};

export default function TotalUsageCard({
  selectedDevices,
  color,
  isGallons,
}: TotalUsageCardProps) {
  const { totalUsage, chartData, labels } = useMemo(() => {
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      return dayjs().subtract(i, "day").format("YYYY-MM-DD");
    }).reverse();

    const usageByDay: { [key: string]: number } = {};
    last7Days.forEach((date) => {
      usageByDay[date] = 0;
    });

    let totalLiters = 0;

    selectedDevices.forEach((device) => {
      device.deviceUsage.forEach((usage) => {
        totalLiters += usage.litersDispensed;

        const date = dayjs(usage.usageTimestamp).format("YYYY-MM-DD");
        if (usageByDay.hasOwnProperty(date)) {
          usageByDay[date] += usage.litersDispensed;
        }
      });
    });

    const chartData = last7Days.map((date) => usageByDay[date]);

    // Calculate trend for last 7 days
    const firstHalf = chartData.slice(0, 3).reduce((a, b) => a + b, 0);
    const secondHalf = chartData.slice(-3).reduce((a, b) => a + b, 0);
    const trend = secondHalf > firstHalf ? "up" : "down";

    return { totalUsage: totalLiters, chartData, trend, labels: last7Days };
  }, [selectedDevices]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total Usage",
        data: chartData,
        borderColor: color,
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      tooltip: {
        ...options.plugins.tooltip,
        isGallons: isGallons,
        callbacks: {
          ...options.plugins.tooltip.callbacks,
          label: function (context: any) {
            const value = context.parsed.y;
            const isGallons = context.chart.options.plugins.tooltip.isGallons;
            const unit = isGallons ? "Gal" : "L";
            const formattedValue = isGallons
              ? formatNumber(value / 3.78541)
              : formatNumber(value);
            return `${formattedValue} ${unit}`;
          },
        },
      },
    },
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      <CardContent sx={{ p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              variant="h5"
              component="h4"
              fontWeight="700"
              sx={{ color: "black" }}
            >
              {isGallons ? (
                <>
                  {formatNumber(totalUsage / 3.78541)} <br /> Gallons
                </>
              ) : (
                <>
                  {formatNumber(totalUsage)} <br /> Liters
                </>
              )}
            </Typography>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography
                variant="subtitle2"
                sx={{
                  display: "inline-block",
                  width: "fit-content",
                  color,
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  borderBottom: `2px solid ${color}`,
                }}
              >
                Total Usage
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" mt={0.5}>
                for {selectedDevices.length} Devices
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: "64px", width: "128px", marginLeft: "8px" }}>
            <Line data={data} options={chartOptions} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
