import React from "react";
import { Box, Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DeviceErrors } from "../../common/types/DeviceTypes";

const columns: GridColDef[] = [
  {
    field: "index",
    headerName: "#",
    filterable: false,
    renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1
  },
  {
    field: "serialNumber",
    headerName: "Serial Number",
    flex: 1,
    minWidth: 175,
  },
  {
    field: "deviceName",
    headerName: "Device Name",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "error",
    headerName: "Error Type",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => (
      <Chip
        label={params.value}
        size="small"
        sx={{
          color: "#fff",
          bgcolor:
            params.value === "SERVICE"
              ? "#f44336"
              : params.value === "LOW FLOW"
                ? "#ff9800"
                : params.value === "LOW CARTRIDGE"
                  ? "#2196f3"
                  : "#4caf50",
        }}
      />
    ),
  },
  {
    field: "date",
    headerName: "Timestamp",
    flex: 1,
    minWidth: 200,
    valueGetter: (_value, row) => new Date(row.date).toLocaleString(),
  },
];

const DeviceErrorsHistoryTable: React.FC<{ deviceErrors: DeviceErrors[] }> = ({
  deviceErrors,
}) => {
  return (
    <Box sx={{ p: 0 }}>
      <DataGrid
        rows={deviceErrors}
        columns={columns}
        getRowId={(row) => row._id}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default DeviceErrorsHistoryTable;
