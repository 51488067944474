import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Chip,
  Snackbar,
  TextField,
  Alert,
  AlertColor,
  Paper,
  Autocomplete,
  Stack,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import CreateNewCustomerModal from "./CreateNewCustomerModal";
import useAuthStore from "../../store/authStore";
import CustomerCard from "./CustomerCard";
import { Customer } from "../../store/useCustomer";
import AssignToGroup from "./AssignToGroupModal";
import { fetchCustomersForUser } from "../../services/customer.service";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

interface CustomerProps {
  onCustomerSelect?: (customer: Customer) => void;
}

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Created Time",
    flex: 1,
    minWidth: 180,
    valueFormatter: (value) => new Date(value).toLocaleString(),
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "country",
    headerName: "Country",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1.5,
    minWidth: 200,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  { field: "email", headerName: "Email", flex: 1.5, minWidth: 225 },
  {
    field: "isActive",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <Chip
        size="small"
        label={params.value ? "Active" : "Inactive"}
        color={params.value ? "success" : "error"}
      />
    ),
  },
];

const CustomerTable: React.FC<CustomerProps> = ({ onCustomerSelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [customers, setCustomers] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const { user, userRole } = useAuthStore.getState();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });
  const [selectedGroup, setSelectedGroup] = useState<Customer | null>(null);
  const [groups, setGroups] = useState<Customer[]>([]);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedCustomerTableTab", newValue.toString());
  };

  const fetchCustomersData = useCallback(async () => {
    try {
      const customers = await fetchCustomersForUser();
      if (customers && Array.isArray(customers)) {
        setCustomers(customers);
        groupCustomersByParent(customers);
      } else {
        setCustomers([]);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomers([]);
    }
  }, []);

  useEffect(() => {
    fetchCustomersData();
  }, [groupModalOpen, fetchCustomersData]);

  const groupCustomersByParent = (customers: any[]) => {
    const groupCustomers = customers.filter(
      (customer) => customer.role === "GroupCustomer"
    );
    const regularCustomers = customers.filter(
      (customer) => customer.role === "Customer"
    );

    const groupedData = groupCustomers.map((group) => {
      const groupMembers = regularCustomers.filter(
        (customer) => customer.customerGroup === group.email
      );

      return {
        ...group,
        members: groupMembers,
      };
    });

    setGroups(groupedData);
  };

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: AlertColor;
  }) => {
    setSnackbar(snackbarData);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    fetchCustomersData();
  };

  const handleEditGroup = (group: Customer) => {
    setSelectedGroup(group);
    setGroupModalOpen(true);
  };

  const nationalAccounts = useMemo(() => {
    return customers.filter((customer) => customer.role === "NationalAccount");
  }, [customers]);

  const globalAccounts = useMemo(() => {
    return customers.filter((customer) => customer.role === "GlobalAccount");
  }, [customers]);

  const getFilteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      if (selectedTab === 1 && customer.role !== "Customer") {
        return false;
      }

      return true;
    })
  }, [customers, selectedTab]);

  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      const isInSelectedGroup =
        !selectedGroup ||
        customer.customerGroup === selectedGroup.email;

      return isInSelectedGroup;
    });
  }, [customers, selectedGroup]);

  const handleGroupSelect = (group: Customer | "All" | null) => {
    if (group === "All" || group === null) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }
  };

  const handleCloseGroupModal = () => {
    setGroupModalOpen(false);
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 2,
            }}
          >
            <Typography sx={{ ml: 1 }} variant={isMobile ? "subtitle1" : "h6"}>
              Manage Your Customers
            </Typography>
            {userRole &&
              [
                "Admin",
                "SuperAdmin",
                "Installer",
                "Distributor",
                "SubDistributor",
              ].includes(userRole) && (
                <StyledButton
                  variantType="primary"
                  icon={<Add />}
                  onClick={handleOpenModal}
                >
                  Add Customer
                </StyledButton>
              )}
            {userRole && ["GroupCustomer"].includes(userRole) && (
              <StyledButton
                variantType="primary"
                icon={<Add />}
                onClick={handleOpenModal}
              >
                Add User
              </StyledButton>
            )}
          </Box>
          <CreateNewCustomerModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSnackbarChange={handleSnackbarChange}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <>
            <Box
              sx={{
                backgroundColor: "transparent",
                borderRadius: 1,
                border: "1px solid grey.800",
                mb: 2,
              }}
            >
              <StyledTabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Device tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <PlainTab label="All" />
                {userRole &&
                  [
                    "Admin",
                    "SuperAdmin",
                    "Installer",
                    "Distributor",
                    "SubDistributor",
                  ].includes(userRole) && <PlainTab label="Customers" />}

                {userRole &&
                  [
                    "Admin",
                    "SuperAdmin",
                    "Installer",
                    "Distributor",
                    "SubDistributor",
                  ].includes(userRole) && <PlainTab label="Groups" />}

                {userRole && ["Admin", "SuperAdmin"].includes(userRole) && (
                  <PlainTab label="National Accounts" />
                )}

                {userRole && ["Admin", "SuperAdmin"].includes(userRole) && (
                  <PlainTab label="Global Accounts" />
                )}
              </StyledTabs>
            </Box>
            {selectedTab === 0 && (
              <DataGrid
                rows={customers}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700",
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}
            {selectedTab === 1 && (
              <DataGrid
                rows={getFilteredCustomers}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700",
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}
            {selectedTab === 2 && (
              <>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <AssignToGroup
                    customers={customers}
                    groupMembers={
                      selectedGroup
                        ? customers.filter(
                          (customer) =>
                            customer.customerGroup === selectedGroup.email
                        )
                        : []
                    }
                    onSubmit={() => { }}
                    onClose={handleCloseGroupModal}
                    open={groupModalOpen}
                    distributor={user?._id || ""}
                    customerGroup={selectedGroup?.email}
                    onSnackbarChange={handleSnackbarChange}
                    onSuccess={() => {
                      fetchCustomersData();
                      setGroupModalOpen(false);
                    }}
                  />
                  {/* Sidebar as the first Grid item */}
                  <Grid item xs={12} sm={3}>
                    <Paper
                      elevation={1}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        height: "100%",
                        position: "sticky",
                        borderRadius: 0,
                      }}
                    >
                      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                        {/* Autocomplete for Group Search */}
                        <Autocomplete
                          options={groups.map((group) => group)}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName} - (${option.email})`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Group"
                              variant="outlined"
                            />
                          )}
                          size="small"
                          sx={{ flexGrow: 1 }}
                          onChange={(_, selectedGroup) => {
                            if (selectedGroup) {
                              handleGroupSelect(selectedGroup);
                            } else {
                              handleGroupSelect(null);
                            }
                          }}
                        />
                      </Stack>

                      {/* List of Groups */}

                      <Divider />
                      <List sx={{ width: "100%", mt: 1 }}>
                        <ListItem
                          disablePadding
                          sx={{ borderBottom: " 1px solid #e0e0e0" }}
                        >
                          <ListItemButton
                            onClick={() => handleGroupSelect("All")}
                            selected={selectedGroup === null}
                          >
                            <ListItemText
                              primary="All"
                              sx={{
                                fontWeight: "bold",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                        {groups.map((group) => (
                          <ListItem
                            key={group._id}
                            disablePadding
                            sx={{
                              bgcolor:
                                selectedGroup === group
                                  ? "secondary.light"
                                  : "transparent",
                              borderBottom: " 1px solid #e0e0e0",
                            }}
                          >
                            <ListItemButton
                              onClick={() => handleGroupSelect(group)}
                              selected={selectedGroup === group}
                            >
                              <ListItemText
                                primary={`${group.firstName} ${group.lastName} - (${group.email})`}
                              />
                            </ListItemButton>
                            <ListItemSecondaryAction>
                              <StyledIconButton
                                variantType="transparent"
                                onClick={() => handleEditGroup(group)}
                              >
                                <EditIcon />
                              </StyledIconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid>

                  {/* Customer Cards */}
                  <Grid item xs={12} sm={9}>
                    <Grid container spacing={2}>
                      {filteredCustomers.length === 0 ? (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ mt: 2 }}
                            >
                              No members in this group
                            </Typography>
                          </Box>
                        </Grid>
                      ) : (
                        filteredCustomers.map((customer) => (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            key={customer._id}
                          >
                            <CustomerCard
                              customer={customer}
                              onRowClick={onCustomerSelect}
                              sx={{ flexGrow: 1, minWidth: 270 }}
                            />
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {selectedTab === 3 && (
              <DataGrid
                rows={nationalAccounts}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700",
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}

            {selectedTab === 4 && (
              <DataGrid
                rows={globalAccounts}
                columns={columns}
                getRowId={(row) => row._id}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "700",
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelection) => {
                  setSelected(newSelection.map((id) => String(id)));
                }}
                rowSelectionModel={selected}
              />
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerTable;
