import { create } from "zustand";
import { fetchIcleanLinesDevices } from "../services/device.service";
import { ICleanLinesDevice } from "../common/types/DeviceTypes";
import useAuthStore from "./authStore";

const startOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
  return newDate;
};

interface ICleanLinesState {
  iCleanLinesDevices: ICleanLinesDevice[];
  selectedIcleanLinesDevices: ICleanLinesDevice[];
  dateFilter: Date | null;
  setDateFilter: (date: Date | null) => void;
  intervalFilter: "daily" | "weekly" | "monthly";
  setIntervalFilter: (interval: "daily" | "weekly" | "monthly") => void;
  setSelectedIcleanLinesDevices: (devices: ICleanLinesDevice[]) => void;
  fetchICleanLinesDevices: () => Promise<void>;
  fetchICleanLinesDeviceDetails: (deviceId: string) => Promise<void>;
}

const useICleanLinesStore = create<ICleanLinesState>((set) => ({
  iCleanLinesDevices: [],
  selectedIcleanLinesDevices: [],
  dateFilter: startOfDay(new Date()),
  intervalFilter: "daily",

  setSelectedIcleanLinesDevices: (devices: ICleanLinesDevice[]) =>
    set({ selectedIcleanLinesDevices: devices }),

  setDateFilter: (date: Date | null) => set({ dateFilter: date }),

  setIntervalFilter: (interval: "daily" | "weekly" | "monthly") =>
    set({ intervalFilter: interval }),

  fetchICleanLinesDevices: async () => {
    const { user } = useAuthStore.getState(); // Get the current user from the auth store
    if (!user) {
      console.error("User not logged in");
      return;
    }
    try {
      const devices = await fetchIcleanLinesDevices();
      set({
        iCleanLinesDevices: devices, // Preserve the original device structure
      });
    } catch (error) {}
  },

  fetchICleanLinesDeviceDetails: async (deviceId: string) => {
    try {
      // const device = await fetchDeviceDetails(deviceId);
      //set({ selectedIcleanLinesDevice: device as ICleanLinesDevice });
    } catch (error) {}
  },
}));

export default useICleanLinesStore;
