import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import BadgeIcon from "@mui/icons-material/Badge";
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeIcon from "@mui/icons-material/Home";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { StyledTextFieldNew } from "../Forms/StyledTextFieldNew";
import CallIcon from "@mui/icons-material/Call";
import { DistributorCreationPayload } from "../../common/types/UserTypes";
import { createDistributor } from "../../services/distributor.service";
import StyledButton from "../Tabs/StyledButton";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (message: string) => void;
}

const CreateNewDistributorModal: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  onError,
}) => {
  const [formData, setFormData] = useState<DistributorCreationPayload>({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postalCode: "",
    phone: "",
  });

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.name as keyof typeof formData;
    const value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.country ||
      !formData.address ||
      !formData.postalCode ||
      !formData.phone ||
      !formData.state ||
      !formData.city
    ) {
      onError("Please fill all required fields.");
      return;
    }
    console.log(formData);
    try {
      await createDistributor(formData);
      onSuccess();
      onClose(); // Close the modal
    } catch (error: any) {
      onError(
        `Error: ${error.response ? error.response.data.message : error.message}`
      );
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "70%", md: "40%" }, // Responsive width
          maxHeight: "90vh", // Max height to maintain within viewport
          overflowY: "auto", // Enables scrolling
          bgcolor: "background.paper",
          p: 4,
          fontFamily: "'Poppins', sans-serif",
          borderRadius: 1,
        }}
      >
        <Box mb={2} textAlign="center">
          <Typography
            variant="h6"
            component="h2"
            fontFamily="'Poppins', sans-serif"
          >
            Create New Distributor
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldNew
              label="First Name"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              required
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BadgeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldNew
              label="Last Name"
              name="lastName"
              onChange={handleChange}
              value={formData.lastName}
              required
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <StyledTextFieldNew
              label="Contact Email"
              name="email"
              onChange={handleChange}
              required
              value={formData.email}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledTextFieldNew
              label="Country"
              name="country"
              onChange={handleChange}
              required
              value={formData.country}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PublicIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledTextFieldNew
              label="State"
              name="state"
              onChange={handleChange}
              required
              value={formData.state}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledTextFieldNew
              label="City"
              name="city"
              onChange={handleChange}
              required
              value={formData.city}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationCityIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledTextFieldNew
              label="Street Address"
              name="address"
              onChange={handleChange}
              required
              value={formData.address}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldNew
              label="Postal Code"
              name="postalCode"
              onChange={handleChange}
              required
              value={formData.postalCode}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPostOfficeIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledTextFieldNew
            label="Contact Phone"
            name="phone"
            onChange={handleChange}
            required
            value={formData.phone}
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CallIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <StyledButton
            onClick={handleSubmit}
            variantType="primary"
            size="large"
          >
            Submit
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateNewDistributorModal;
