import {
  DeviceData,
  DeviceErrors,
  DeviceLog,
  DeviceLogEntry,
  ICleanLinesDeviceData,
} from "../common/types/DeviceTypes";
import { updateTestStatusPayload } from "../common/types/payload";
import axios from "axios";
import useAuthStore from "../store/authStore";
import { EmailNotificationTypes } from "../common/types/DeviceTypes";

const API_URL = process.env.REACT_APP_API_URL; // LOTUS PRO
const ICLEANLINES_API_URL = process.env.REACT_APP_ICLEAN_API_URL; // ICLEANLINES

/* ========= Lotus Pro ========= */

export const fetchDevices = async (): Promise<DeviceData[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/device/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch devices:", error);
    throw new Error("Failed to fetch devices");
  }
};

export const createDevice = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/device`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const fetchDeviceDetails = async (
  deviceId: string
): Promise<DeviceData> => {
  const response = await axios.get(`${API_URL}/device/?deviceId=${deviceId}`);
  return response.data;
};

export const createICleanDevice = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/iclean/device`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const createICleanlinesDevice = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/icleanlines/device`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const fetchIcleanDevices = async (): Promise<DeviceData[]> => {
  const response = await fetch(`${API_URL}/icleanlines/device/all`);
  if (!response.ok) {
    throw new Error("Failed to fetch devices");
  }
  return response.json();
};

export const updateDeviceTestStatus = async (
  deviceId: string,
  testStatus: updateTestStatusPayload
) => {
  try {
    const response = await axios.put(`${API_URL}/device/test-status`, {
      deviceId,
      testStatus,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating device test status:", error);
    throw error;
  }
};

export const fetchDeviceErrors = async (): Promise<DeviceErrors[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/device-error/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.deviceErrors;
  } catch (error) {
    console.error("Failed to fetch device errors:", error);
    throw new Error("Failed to fetch device errors");
  }
};

export const fetchTodaysDeviceErrors = async (): Promise<DeviceErrors[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/device-error/today`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.deviceErrors;
  } catch (error) {
    console.error("Failed to fetch today's device errors:", error);
    throw new Error("Failed to fetch today's device errors");
  }
};

export const fetchDeviceLogs = async (
  email: string,
  role: string
): Promise<DeviceLog[]> => {
  const response = await axios.get(`${API_URL}/device-logs/all`, {
    params: { email, role },
  });
  return response.data.deviceLogs;
};

export const fetchDeviceLogsByDeviceId = async (
  deviceId: string
): Promise<DeviceLogEntry[]> => {
  const response = await axios.get(`${API_URL}/device-logs`, {
    params: { deviceId },
  });
  return response.data.deviceLogs;
};

export const changeCartridgeType = async (
  deviceId: string,
  newCartridge: string
) => {
  try {
    const response = await axios.post(`${API_URL}/device/change-cartridge`, {
      deviceId,
      newCartridge,
    });

    console.log("Cartridge type updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating cartridge type:", error);
    throw error;
  }
};

export const addAdditionalUsers = async (
  deviceId: string,
  userEmail: string
) => {
  try {
    const response = await axios.post(`${API_URL}/device/add-user`, {
      deviceId,
      userEmail,
    });
    console.log("Additional users added successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding additional users:", error);
    throw error;
  }
};

export const replaceICleanDevice = async (
  oldDeviceId: string,
  newDeviceId: string,
  reasonForReplacement: string | null
) => {
  try {
    const response = await axios.post(`${API_URL}/iclean/device/replace`, {
      oldDeviceId,
      newDeviceId,
      reasonForReplacement,
    });
    return response.data;
  } catch (error) {
    console.error("Error replacing IClean device:", error);
    throw error;
  }
};

export const getICleanReplacementHistory = async (deviceId: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/iclean/device/replacement-history`,
      {
        deviceId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching IClean replacement history:", error);
    throw error;
  }
};

export const sendDeviceInstallationEmail = async (
  email: string,
  deviceName: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/mailing/send-device-installation-email`,
      {
        email,
        deviceName,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending device installation email:", error);
    throw error;
  }
};

export const sendOldICleanlinesDeviceEmail = async (
  email: string,
  deviceName: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/mailing/send-device-old-icleanlines-email`,
      {
        email,
        deviceName,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending old ICleanlines device email:", error);
    throw error;
  }
};

export const updateDeviceDetails = async (
  deviceId: string,
  deviceInformation: any,
  deviceLocation: any
) => {
  try {
    const response = await axios.put(`${API_URL}/device/update-details`, {
      deviceId,
      deviceInformation,
      deviceLocation,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating device details:", error);
    throw error;
  }
};

export const deleteDevice = async (deviceId: string) => {
  try {
    const response = await axios.post(`${API_URL}/device/mark-inactive`, {
      deviceId,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting device:", error);
    throw error;
  }
};

export const fetchCoordinatesFromAddress = async (
  address: string
): Promise<{ lat: number; lng: number }> => {
  try {
    // Make a request to your backend API endpoint
    const response = await axios.get(`${API_URL}/geolocation/geocode`, {
      params: { address },
    });

    if (response.data.lat && response.data.lng) {
      return { lat: response.data.lat, lng: response.data.lng };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    throw error;
  }
};

export const fetchDeviceInstaller = async (deviceId: string) => {
  try {
    const response = await axios.post(`${API_URL}/device/installer`, {
      deviceId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching device installer:", error);
    throw error;
  }
};

export const updateDeviceNotifications = async (
  deviceId: string,
  emailNotifications: EmailNotificationTypes[]
): Promise<any> => {
  try {
    const response = await axios.patch(`${API_URL}/notifications/${deviceId}`, {
      emailNotifications,
    });
    return response.data; // Return the response data
  } catch (error: any) {
    console.error("Error updating device notifications:", error);
    throw new Error(
      error.response?.data?.message || "Failed to update device notifications"
    );
  }
};

// ========= iCleanLines =========

// fetch all iCleanLines devices
export const fetchIcleanLinesDevices = async (): Promise<
  ICleanLinesDeviceData[]
> => {
  const response = await fetch(`${ICLEANLINES_API_URL}/device/all`);
  if (!response.ok) {
    throw Error("Failed to fetch devices");
  }
  return response.json();
};

export const fetchOldIcleanLinesDevices = async (): Promise<DeviceData[]> => {
  const response = await fetch(`${API_URL}/iclean/device/all`);
  if (!response.ok) {
    throw Error("Failed to fetch devices");
  }
  return response.json();
};
