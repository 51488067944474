import axios from "axios";
import {
  ChangeRequestPayload,
} from "../common/types/ChangeRequestTypes";
const API_URL = process.env.REACT_APP_API_URL;

// Function to create a new change request
export const createChangeRequest = async (
  changeRequest: ChangeRequestPayload
) => {
  try {
    const response = await axios.post(
      `${API_URL}/change-request/add`,
      changeRequest
    );
    return response.data;
  } catch (error: any) {
    console.error("Failed to create change request:", error);
    if (error.response?.status === 409) {
      throw new Error("A similar request is already pending for this item");
    }
    throw new Error(
      error.response?.data?.message || "Failed to create change request"
    );
  }
};

// Function to fetch all change requests
export const fetchChangeRequests = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_URL}/change-request/all`);
    return response.data;
  } catch (error: any) {
    console.error("Failed to fetch change requests:", error);
    throw new Error("Failed to fetch change requests");
  }
};

// Function to fetch change requests for a distributor
export const fetchChangeRequestsForDistributor = async (
  distributorEmail: string
): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/change-request/distributor/${distributorEmail}`
    );
    return response.data;
  } catch (error: any) {
    console.error("Failed to fetch change requests:", error);
    throw new Error("Failed to fetch change requests");
  }
};

// Function to approve a change request
export const approveChangeRequest = async (
  id: string,
  reviewerDetails: { reviewedBy: string }
) => {
  try {
    const response = await axios.put(`${API_URL}/change-request/${id}/review`, {
      status: "Approved",
      ...reviewerDetails,
    });
    return response.data;
  } catch (error: any) {
    console.error("Failed to approve change request:", error);
    throw new Error("Failed to approve change request");
  }
};

// Function to reject a change request
export const rejectChangeRequest = async (
  id: string,
  reviewerDetails: { reviewedBy: string }
) => {
  try {
    const response = await axios.put(`${API_URL}/change-request/${id}/review`, {
      status: "Rejected",
      ...reviewerDetails,
    });
    return response.data;
  } catch (error: any) {
    console.error("Failed to reject change request:", error);
    throw new Error("Failed to reject change request");
  }
};
