import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";

const CustomerSupport: React.FC = () => {
  return (
    <Box p={0}>
      <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "90%", md: "90%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
              borderColor: "divider",
              p: 2,
            }}
          >
            <Typography variant="h5">Help Desk</Typography>
          </Box>
          <Box
            textAlign="center"
            my={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
              variant="body1"
              gutterBottom
            >
              Chat <ChatIcon fontSize="medium" />
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body1"
            >
              {" "}
              <a href="mailto:dev.team@tersano.com"> dev.team@tersano.com</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body2"
              gutterBottom
            >
              Monday - Friday: 6am - 5pm MT
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body2"
              gutterBottom
            >
              Saturday & Sunday: 10am - 11:45am MT and 1pm - 3pm MT
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body1"
              gutterBottom
            >
              or Call
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body1"
              gutterBottom
            >
              <a href="tel:1-855-688-7269">1-226-961-4154</a>
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
              }}
              variant="body2"
            >
              Monday - Friday: 8am - 5pm MT
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerSupport;
