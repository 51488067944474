import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  TextField,
  Autocomplete,
  Chip,
} from "@mui/material";
import StyledButton from "../Tabs/StyledButton";
import { Loop } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DeviceData } from "../../common/types/DeviceTypes";
import ReplacementHistoryTimeline from "../Timeline/ReplacementHistoryTimeline";

interface ReplaceDeviceProps {
  devices: DeviceData[];
  onSuccess: (newDevice: any) => void;
}

const ReplaceDevice: React.FC<ReplaceDeviceProps> = ({ devices }) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceData | null>(null);
  const [newDeviceDetails, setNewDeviceDetails] = useState({
    deviceId: "",
    serial: "",
    modelNumber: "",
  });

  const handleSelectDevice = (_event: any, newValue: DeviceData | null) => {
    setSelectedDevice(newValue);
    if (newValue) {
      setNewDeviceDetails({
        deviceId: newValue.deviceInformation.deviceName,
        serial: newValue.deviceInformation.serial,
        modelNumber: "",
      });
    }
  };

  return (
    <Box p={3}>
      <Autocomplete
        options={devices}
        getOptionLabel={(option) =>
          `${option.deviceInformation.deviceName} (${option.deviceInformation.serial})`
        }
        style={{ marginBottom: 16 }}
        onChange={handleSelectDevice}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Device"
            variant="outlined"
            fullWidth
          />
        )}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={5}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="subtitle1">Old Device Details</Typography>
            <Divider sx={{ my: 1 }} />
            {selectedDevice ? (
              <>
                <Typography variant="body1" gutterBottom>
                  <strong>Device name:</strong>{" "}
                  {selectedDevice.deviceInformation.deviceName}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Serial number:</strong>{" "}
                  {selectedDevice.deviceInformation.serial}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Location:</strong>{" "}
                  {selectedDevice.deviceLocation?.city},{" "}
                  {selectedDevice.deviceLocation?.state},{" "}
                  {selectedDevice.deviceLocation?.country},{" "}
                  {selectedDevice.deviceLocation?.address},{" "}
                  {selectedDevice.deviceLocation?.postalCode},{" "}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Ping Status: </strong>
                  <Chip
                    size="small"
                    label={selectedDevice.pingStatus.status}
                    color={
                      selectedDevice.pingStatus.status === "ONLINE"
                        ? "success"
                        : "error"
                    }
                  />
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Customer Email :</strong>{" "}
                  {selectedDevice.deviceInformation.customerEmail}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Installer Email:</strong>{" "}
                  {selectedDevice.deviceInformation.installerEmail}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Current Cartridge:</strong>{" "}
                  {selectedDevice.deviceInformation.currentCartridgeType}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Date Installed</strong> {selectedDevice.createdAt}
                </Typography>
              </>
            ) : (
              <Typography>Select a device to see details</Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} display="flex" justifyContent="center">
          <ArrowForwardIcon sx={{ color: "primary.main", fontSize: 40 }} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="subtitle1">New Device Details</Typography>
            <Divider sx={{ my: 1 }} />
            <TextField
              fullWidth
              label="Device Name"
              value={newDeviceDetails.deviceId}
              onChange={(e) =>
                setNewDeviceDetails({
                  ...newDeviceDetails,
                  deviceId: e.target.value,
                })
              }
              margin="dense"
              variant="outlined"
              sx={{ mb: 1 }}
            />
            <TextField
              fullWidth
              label="Serial Number"
              value={newDeviceDetails.serial}
              onChange={(e) =>
                setNewDeviceDetails({
                  ...newDeviceDetails,
                  serial: e.target.value,
                })
              }
              margin="dense"
              variant="outlined"
            />
          </Paper>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="center">
        <StyledButton variantType="primary" icon={<Loop />}>Replace</StyledButton>
      </Box>
      {
        selectedDevice && (
          <Box sx={{ mt: 4, width: "100%" }}>
            <Typography variant="body1" gutterBottom sx={{ ml: 2 }}>
              Replacement History:
            </Typography>
            <Divider sx={{ my: 1 }} />
            <ReplacementHistoryTimeline
              deviceId={selectedDevice.deviceInformation.deviceId}
            />
          </Box>
        )
      }
    </Box >
  );
};

export default ReplaceDevice;
