import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  SxProps,
  Theme,
} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";
import { Customer } from "../../store/useCustomer";

interface CustomerCardProps {
  customer: Customer | undefined;
  onRowClick?: (customer: Customer) => void;
  sx?: SxProps<Theme>;
}

const CustomerCard: React.FC<CustomerCardProps> = ({
  customer,
  onRowClick,
  sx,
}) => {
  if (!customer) {
    return null;
  }
  const handleCardClick = () => {
    if (onRowClick) {
      onRowClick(customer);
    }
  };

  return (
    <Card
      sx={{
        minWidth: 300,
        maxWidth: 300,
        position: "relative",
        cursor: "pointer",
        borderRadius: 0,
        ...sx,
      }}
      onClick={handleCardClick}
    >
      <CardContent>
        <Box display="flex" alignItems="center" gap={2} sx={{ marginLeft: 0 }}>
          <Box>
            <Avatar sx={{ bgcolor: "primary.main", width: 50, height: 50 }}>
              <FaceIcon sx={{ width: 30, height: 30 }} />
            </Avatar>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" noWrap>
              {customer.firstName} {customer.lastName}
            </Typography>
            <Typography
              sx={{
                fontSize: "clamp(0.75rem, 0.8rem, 0.875rem)",
                fontWeight: 500,
              }}
              variant="subtitle1"
              noWrap
            >
              {customer.email}
            </Typography>

            <Typography variant="subtitle2" noWrap>
              {customer.country}, {customer.state}, {customer.city}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {customer.address}, {customer.postalCode}
            </Typography>
            <Typography variant="subtitle2" noWrap>
              Phone: {customer.phone}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <Box
        sx={{
          backgroundColor: "primary.main",
          p: "1.5px",
          textAlign: "center",
        }}
      />
    </Card>
  );
};

export default CustomerCard;
