import React, { useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";
import PlainTab from "../Tabs/PlainTab";
import { StyledTabs } from "../Tabs/IconTab";
import { DeviceData } from "../../common/types/DeviceTypes";
import WarrantyReportHistoryTable from "../Reports/WarrantyReportHistory";
import GenerateWarrantyReport from "../Reports/GenerateWarrantyReport";

interface ServiceReportsProps {
  devices: DeviceData[];
}

export const ServiceReports: React.FC<ServiceReportsProps> = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedServiceReportsTab", newValue.toString());
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "96%", md: "95%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
              mb: 1,
            }}
          >
            <Typography variant="h5">Service Reports</Typography>
          </Box>

          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="Profile tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <PlainTab label="Reports" />
            <PlainTab label="Generate Report" />
            <PlainTab label="Schedule Reports" />
            <PlainTab label="Archive Reports" />
          </StyledTabs>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>

          <Box sx={{ mt: 1 }}>
            {" "}
            {selectedTab === 0 && (
              <WarrantyReportHistoryTable reportCategory="WarrantyReport" />
            )}
            {selectedTab === 1 && (
              <GenerateWarrantyReport reportCategory="WarrantyReport" />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
