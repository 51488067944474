import React, { useState } from "react";
import {
  Box,
  Tooltip,
  Chip,
  Alert,
  AlertColor,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenIcon,
} from "@mui/icons-material";
import useAuthStore from "../../store/authStore";
import { DeviceData } from "../../common/types/DeviceTypes";
import {
  ChangeRequestPayload,
  ChangeRequestTypes,
} from "../../common/types/ChangeRequestTypes";
import { createChangeRequest } from "../../services/change-request.service";
import EditDeviceDetailsModal from "./EditDeviceDetailsModal";
import StyledButton from "../Tabs/StyledButton";
import StyledIconButton from "../Tabs/StyledIconButton";

const DeviceHistoryTable: React.FC<{
  devices: DeviceData[];
  onRowClick: (device: DeviceData) => void;
}> = ({ devices, onRowClick }) => {
  const { user, userRole } = useAuthStore.getState();
  const [selected, setSelected] = useState<string[]>([]);
  const [deviceData, setDeviceData] = useState<DeviceData | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });

  const handleOpenEditModal = (data: DeviceData) => {
    setEditModalOpen(true);
    setDeviceData(data);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setDeviceData(null);
  };

  const handleOpenDeleteModal = (data: DeviceData) => {
    setDeleteModalOpen(true);
    setDeviceData(data);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeviceData(null);
  };

  const handleSaveDeviceDetails = (_response: any) => {
    setSnackbar({
      open: true,
      message: "A request has been Submitted to Update Device Details",
      severity: "warning",
    });
    handleCloseEditModal();
    setDeviceData(null);
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
    setDeviceData(null);
  };

  // Change the page to the specific page of the device
  const handleClick = (device: DeviceData) => {
    onRowClick(device);
  };

  const handleDeleteDevice = async () => {
    try {
      if (!user || !userRole) return;

      const changeRequestPayload: ChangeRequestPayload = {
        type: ChangeRequestTypes.DeleteDevice,
        userEmail: user?.email,
        userRole: userRole,
        details: {
          deviceId: deviceData?.deviceInformation.deviceId,
        },
      };
      // Send the change request to the server
      await createChangeRequest(changeRequestPayload);

      // Show success message
      setSnackbar({
        open: true,
        message: `Change Request delete to device: ${deviceData?.deviceInformation.deviceId} created successfully`,
        severity: "warning",
      });
    } catch (error: any) {
      console.error("Error creating change request:", error.message);
      setSnackbar({
        open: true,
        message: error.message
          ? error.message
          : "Failed to create change request.",
        severity: "error",
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "serial",
      headerName: "Serial Number",
      flex: 1,
      minWidth: 180,
      valueGetter: (_value, row) => row.deviceInformation.serial,
    },
    {
      field: "deviceName",
      headerName: "Device Name",
      flex: 1,
      minWidth: 130,
      valueGetter: (_value, row) => row.deviceInformation.deviceName,
    },
    {
      field: "country",
      headerName: "Location",
      flex: 1,
      minWidth: 130,
      valueGetter: (_value, row) => row.deviceLocation?.country,
    },
    {
      field: "customerEmail",
      headerName: "Customer Email",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.deviceInformation.customerEmail,
    },
    {
      field: "installerEmail",
      headerName: "Installer Email",
      flex: 1,
      minWidth: 200,
      valueGetter: (_value, row) => row.deviceInformation.installerEmail,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const status = params.row.pingStatus.status;
        let chipColor: "success" | "error" | "default";
        let label: string;

        switch (status) {
          case "ONLINE":
            chipColor = "success";
            label = "Online";
            break;
          case "OFFLINE":
            chipColor = "error";
            label = "Offline";
            break;
          default:
            chipColor = "default";
            label = "Unknown";
        }

        return (
          <Chip
            size="small"
            label={label}
            color={chipColor}
          />
        );
      },
    },
    {
      field: "lastPing",
      headerName: "Last Ping",
      flex: 1.5,
      minWidth: 175,
      valueGetter: (_value, row) =>
        new Date(row.pingStatus.timestamp).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Box sx={{
            display: "flex",
            alignItems: 'center',
            alignContent: "center",
            height: "100%",
            width: "100%",
            gap: 1
          }}>
            <Tooltip title="Open">
              <StyledIconButton variantType="transparent" onClick={() => handleClick(row)}>
                <OpenIcon />
              </StyledIconButton>
            </Tooltip>
            {userRole && ["Distributor", "SuperAdmin", "Admin"].includes(userRole) && (
              <Box sx={{ display: "flex" }}>
                <Tooltip title="Edit">
                  <StyledIconButton
                    variantType="transparent"
                    onClick={() => handleOpenEditModal(row)}
                  >
                    <EditIcon />
                  </StyledIconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <StyledIconButton
                    variantType="transparent"
                    onClick={() => handleOpenDeleteModal(row)}
                  >
                    <DeleteIcon />
                  </StyledIconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ p: 0 }}>
      <DataGrid
        rows={devices}
        columns={columns}
        getRowId={(row) => row._id}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "700",
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newSelection) => {
          setSelected(newSelection.map((id) => String(id)));
        }}
        rowSelectionModel={selected}
      />
      {deviceData && (
        <EditDeviceDetailsModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          device={deviceData}
          onSave={handleSaveDeviceDetails}
          onError={handleError}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>
          <StyledButton
            onClick={() => {
              handleDeleteDevice();
              handleCloseDeleteModal();
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeviceHistoryTable;
