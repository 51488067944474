import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Chip,
  Snackbar,
  Alert,
  AlertColor,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import useAuthStore from "../../store/authStore";
import { fetchSubDistributors } from "../../services/distributor.service";
import CreateSubDistributorModal from "./CreateSubDistributorModal";
import StyledButton from "../Tabs/StyledButton";

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Created Time",
    flex: 1,
    minWidth: 180,
    valueFormatter: (value) => new Date(value).toLocaleString(),
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "country",
    headerName: "Country",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1.5,
    minWidth: 200,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  { field: "email", headerName: "Email", flex: 1.5, minWidth: 225 },
  {
    field: "isActive",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <Chip
        size="small"
        label={params.value ? "Active" : "Inactive"}
        color={params.value ? "success" : "error"}
      />
    ),
  },
];

const DistributorTableV2: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useAuthStore();
  const [subDistributors, setSubDistributors] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  // Fetch sub-distributors when Distributors or user role changes
  const fetchSubDistributorsData = useCallback(async () => {
    if (!user?._id) return;

    try {
      const subDistributorsData = await fetchSubDistributors();
      setSubDistributors(subDistributorsData);
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    fetchSubDistributorsData();
  }, [fetchSubDistributorsData]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleDistributorCreationSuccess = () => {
    setSnackbar({
      open: true,
      message: "Distributor successfully created.",
      severity: "success",
    });
    fetchSubDistributorsData(); // Refresh the list after creation
    handleCloseModal(); // Close the modal after success
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  const handleSnackbarChange = (snackbarData: {
    open: boolean;
    message: string;
    severity: "success" | "error";
  }) => {
    setSnackbar(snackbarData);
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 2,
            }}
          >
            <Typography sx={{ ml: 1 }} variant={isMobile ? "subtitle1" : "h6"}>
              Manage Your Sub-Distributors
            </Typography>
            <StyledButton
              variantType="primary"
              icon={<Add />}
              onClick={handleOpenModal}
            >
              Add Sub-Distributor
            </StyledButton>
          </Box>
          <DataGrid
            rows={subDistributors}
            columns={columns}
            getRowId={(row) => row._id}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "700",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              setSelected(newSelection.map((id) => String(id)));
            }}
            rowSelectionModel={selected}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1.5,
            backgroundColor: "#f5f5f5",
            mb: 1,
          }}
        >
          <CreateSubDistributorModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSuccess={handleDistributorCreationSuccess}
            onError={handleError}
            onSnackbarChange={handleSnackbarChange}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default DistributorTableV2;
