import axios from "axios";
import { DeviceTest, OzoneResults } from "../common/types/DeviceTestTypes";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchDeviceTestByDeviceId = async (
  deviceId: string
): Promise<DeviceTest[]> => {
  const response = await axios.get(`${API_URL}/device-test/all`, {
    params: { deviceId },
  });
  return response.data;
};

export const fetchAllDeviceTests = async (): Promise<DeviceTest[]> => {
  const response = await axios.get(`${API_URL}/device-test/all`);
  return response.data;
};

export const fetchDeviceTestsByTester = async (
  tester: string,
  limit?: number
): Promise<DeviceTest[]> => {
  const response = await axios.post(`${API_URL}/device-test/tester`, {
    tester: tester,
    limit: limit
  });
  return response.data;
};

export const createDeviceTest = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/device-test`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const completeDeviceTest = async (
  deviceId: string,
  updates: Partial<DeviceTest>
) => {
  try {
    const response = await axios.put(
      `${API_URL}/device-test/complete-test/${deviceId}`,
      updates
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const updateDeviceTest = async (payload: any) => {
  try {
    const response = await axios.put(`${API_URL}/device-test`, payload);
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const addOzoneResults = async (payload: OzoneResults) => {
  try {
    const response = await axios.put(
      `${API_URL}/device-test/add-ozone-result`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};
