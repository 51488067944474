import React from "react";
import { Box } from "@mui/material";
import ReplaceDeviceComponent from "../Device/ReplaceDevice";
import { DeviceData } from "../../common/types/DeviceTypes";

interface ReplaceDeviceProps {
  devices: DeviceData[];
  onSuccess: (newDeviceId: string) => void;
}

const ReplaceDevice: React.FC<ReplaceDeviceProps> = ({
  devices,
  onSuccess,
}) => {
  const handleSuccess = (newDevice: any) => {
    onSuccess(newDevice);
  };
  return (
    <Box>
      <ReplaceDeviceComponent devices={devices} onSuccess={handleSuccess} />
    </Box>
  );
};

export default ReplaceDevice;
