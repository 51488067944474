import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Divider,
  Snackbar,
  Alert,
  AlertColor,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";
import { Customer } from "../../store/useCustomer";
import {
  fetchCustomersByEmails,
  deleteCustomer,
} from "../../services/customer.service";
import { fetchDevices } from "../../services/device.service";
import { StyledTabs } from "../Tabs/IconTab";
import PlainTab from "../Tabs/PlainTab";
import { DeviceData } from "../../common/types/DeviceTypes";
import { CustomerDeviceAccess } from "./CustomerDeviceAccess";
import MapView from "../Product/Charts/MapViewDeviceDetails";
import StyledButton from "../Tabs/StyledButton";

interface CustomerDetailsProps {
  customer: Customer;
  onBack: () => void;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  customer,
  onBack,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [customerData, setCustomerData] = useState(customer);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as AlertColor,
  });
  const [availableDevices, setAvailableDevices] = useState<DeviceData[]>([]);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDeleteCustomer = async () => {
    try {
      await deleteCustomer(customerData._id);
      setSnackbar({
        open: true,
        message: `Customer ${customerData.firstName} ${customerData.lastName} deleted successfully`,
        severity: "success",
      });
      setTimeout(() => {
        onBack(); // Navigate back after deletion
      }, 2000);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setSnackbar({
          open: true,
          message: error.response.data.message ?? "Failed to delete customer",
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const updatedCustomer: Customer = await (
          await fetchCustomersByEmails([customer.email])
        ).customers[0];
        setCustomerData(updatedCustomer);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };
    fetchDetails();
  }, [customer._id, customer.email]);

  const fetchCustomerDevices = useCallback(async () => {
    try {
      const distributorDevices = await fetchDevices();
      setAvailableDevices(distributorDevices);
    } catch (error) {
      console.error("Failed to fetch customer devices:", error);
    }
  }, []);

  useEffect(() => {
    fetchCustomerDevices();
  }, [fetchCustomerDevices]);

  const center = customer.address
    ? {
      lat: 32.7767,
      lng: -96.797,
    }
    : { lat: 39.8283, lng: -82.955803 };

  const zoom = 12;
  const markers = customer.address
    ? [
      {
        id: customer._id,
        position: {
          lat: 32.7767,
          lng: -96.797,
        },
        name: customer.firstName + " " + customer.lastName,
      },
    ]
    : [];
  const SectionHeader = ({ title }: { title: string }) => (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Typography
        variant="h6"
        sx={{
          flexShrink: 0,
          pr: 2,
          textAlign: "center",
          color: "#282828",
          fontWeight: "700",
          fontFamily: "Montserrat, sans-serif",
          fontSize: "14px",
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{ flexGrow: 1, borderBottomWidth: 3, bgcolor: "secondary.main" }}
      />
    </Box>
  );

  return (
    <Box sx={{ flex: 1, p: { xs: 3, sm: 4 } }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          p: 2,
        }}
      >
        <Toolbar
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            textAlign: { xs: "center", sm: "left" },
            gap: 2,
          }}
        >
          <IconButton onClick={onBack} sx={{ marginRight: { sm: 2 } }}>
            <ArrowBackIcon />
          </IconButton>
          <Avatar
            sx={{
              width: 56,
              height: 56,
            }}
          >
            <PersonIcon />
          </Avatar>
          <Box sx={{ flexGrow: 1, textAlign: { xs: "center", sm: "left" } }}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {customerData.firstName} {customerData.lastName}
            </Typography>
            <Typography variant="body1">Email: {customerData.email}</Typography>
            <Typography variant="body1">Phone: {customerData.phone}</Typography>
          </Box>
          <StyledButton
            variantType="destructive"
            icon={<DeleteIcon />}
            onClick={handleOpenDeleteModal}
            sx={{ mt: { xs: 2, sm: 0 } }}
          >
            Delete Customer
          </StyledButton>
        </Toolbar>
      </AppBar>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Box sx={{ maxWidth: "100%", mx: "auto", mt: 2 }}>
        <StyledTabs
          value={selectedTab}
          onChange={handleChangeTab}
          aria-label="Customer tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <PlainTab label="Devices" />
          <PlainTab label="Overview" />
        </StyledTabs>

        {/* Content based on selected tab */}
        {selectedTab === 0 && (
          <Box sx={{ p: 1 }}>
            <CustomerDeviceAccess
              devices={availableDevices}
              customer={customerData}
              onSubmit={() => { }}
            />
          </Box>
        )}
        {selectedTab === 1 && (
          <>
            <Box sx={{ p: 3 }}>
              {/* Section 1: Customer Overview */}
              <SectionHeader title="Customer Overview" />

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Name
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {customerData.firstName} {customerData.lastName}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {customerData.email}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Phone
                    </Typography>
                    <Typography variant="body1">
                      {customerData.phone}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* Section 2: Customer Address */}
              <Box sx={{ mt: 4 }}>
                <SectionHeader title="Customer Address" />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Street Address
                      </Typography>
                      <Typography variant="body1">
                        {customerData.address}
                      </Typography>
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          gutterBottom
                        >
                          City
                        </Typography>
                        <Typography variant="body1">
                          {customerData.city}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          gutterBottom
                        >
                          State
                        </Typography>
                        <Typography variant="body1">
                          {customerData.state}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            gutterBottom
                          >
                            Country
                          </Typography>
                          <Typography variant="body1">
                            {customerData.country}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            gutterBottom
                          >
                            Postal Code
                          </Typography>
                          <Typography variant="body1">
                            {customerData.postalCode}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
        {selectedTab === 2 && (
          <Box sx={{ mt: 2 }}>
            <MapView
              center={center}
              zoom={zoom}
              height="720px"
              markers={markers}
            />
          </Box>
        )}
      </Box>

      <Dialog open={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this customer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDeleteModal}>Cancel</StyledButton>

          <StyledButton
            onClick={() => {
              handleDeleteCustomer();
              handleCloseDeleteModal();
            }}
            variantType="destructive"
          >
            Delete
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerDetails;
