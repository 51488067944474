import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Papa from "papaparse";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import {
  SalesData,
  ProductType,
  AccountType,
} from "../../common/types/DistributorSalesTypes"; // Import types as needed
import { uploadSalesReports } from "../../services/distributor.service"; // Import the bulk creation function
import StyledButton from "../Tabs/StyledButton";

interface UploadCsvModalProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess: (data: SalesData[], errorMessage?: string) => void; // Add optional errorMessage
  distributorEmail: string;
}

const UploadCsvModal: React.FC<UploadCsvModalProps> = ({
  open,
  onClose,
  onUploadSuccess,
  distributorEmail,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [csvData, setCsvData] = useState<SalesData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const steps = ["Upload File", "Summary"];

  const validateCsvData = (
    data: SalesData[]
  ): { isValid: boolean; errors: string[] } => {
    const errors: string[] = [];

    data.forEach((row, index) => {
      // Validate Product Type
      if (!Object.values(ProductType).includes(row.product)) {
        errors.push(`Row ${index + 1}: Invalid product type.`);
      }

      // Product type is NONE
      if (row.product === ProductType.NONE) {
        errors.push(`Row ${index + 1}: Product type cannot be NONE.`);
      }

      // Serial Number is mandatory for Lotus PRO
      if (row.product === ProductType.LOTUS_PRO && !row.serialNumber) {
        errors.push(
          `Row ${index + 1}: Serial number is required for Lotus PRO.`
        );
      }

      // Validate Account Type
      if (!Object.values(AccountType).includes(row.account.accountType)) {
        errors.push(`Row ${index + 1}: Invalid account type.`);
      }

      // Account Type is NONE
      if (row.account.accountType === AccountType.NONE) {
        errors.push(`Row ${index + 1}: Account type cannot be NONE.`);
      }

      // Validate Account Information if Account Type is Selected
      if (
        row.account.accountType !== AccountType.NONE &&
        (!row.account.accountName ||
          !row.account.accountEmail ||
          !row.account.accountPhone)
      ) {
        errors.push(
          `Row ${
            index + 1
          }: Missing account information (Name, Email, or Phone).`
        );
      }
    });

    return { isValid: errors.length === 0, errors };
  };

  const handleNext = () => {
    if (activeStep === 0 && csvFile) {
      parseCsvFile();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    resetForm();
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setCsvFile(null);
    setCsvData([]);
    setSelectedFileName(null);
    setActiveStep(0);
    setLoading(false);
    setValidationErrors([]);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFileName(file.name);
      setCsvFile(file); // Only set the file here, no parsing
    }
  };

  // Update the parseCsvFile function to accept a callback
  const parseCsvFile = () => {
    if (!csvFile) return;
    setLoading(true);

    Papa.parse(csvFile, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        const parsedData = result.data
          .map((row: any) => ({
            product: row.Product || ProductType.NONE,
            serialNumber: row["Serial Number"]?.toString() || "",
            account: {
              accountType:
                mapToAccountType(row["Account Type"]?.trim()) ||
                AccountType.NONE,
              accountName: row["Account Name"]?.trim() || "N/A",
              accountEmail: row["Account Email"]?.trim() || "",
              accountPhone: row["Account Phone"]?.toString() || "",
              accountAddress: row["Account Address"] || "",
              siteContact: row["Site Contact"]?.trim() || "",
            },
            notes: row.Notes || "-",
            distributorEmail: distributorEmail,
            date: row.Date ? new Date(row.Date) : undefined,
          }))
          .filter(
            (row) =>
              row.account.accountName &&
              row.account.accountEmail &&
              row.account.accountPhone
          ); // Filter out rows missing required fields

        setCsvData(parsedData);
        setLoading(false);
        setActiveStep(1);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        setLoading(false);
      },
    });
  };

  // Mapping function to map CSV string values to the AccountType Enum
  const mapToAccountType = (
    accountTypeStr: string
  ): AccountType | undefined => {
    const accountType = Object.values(AccountType).find(
      (type) => type.toLowerCase() === accountTypeStr?.toLowerCase()
    );
    return accountType as AccountType | undefined;
  };

  // Function to handle bulk sales report submission
  const handleSubmit = async () => {
    const { isValid, errors } = validateCsvData(csvData);

    if (!isValid) {
      setValidationErrors(errors); // Display validation errors
      return; // Stop submission if validation fails
    }

    // Clear validation errors if data is valid
    setValidationErrors([]);
    try {
      setLoading(true);
      const response = await uploadSalesReports(csvData);
      onUploadSuccess(response); // Call the success callback
      resetForm();
      handleClose();
    } catch (error) {
      console.error("Error uploading sales reports:", error);
      onUploadSuccess([], "Failed to upload sales reports");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Upload CSV File</DialogTitle>
      <DialogContent>
        <Stepper
          activeStep={activeStep}
          sx={{ mb: 3, maxWidth: 500, mx: "auto" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Step 1: Upload CSV */}
        {activeStep === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Please ensure your CSV file follows our format. You can download
                the{" "}
                <a
                  href="https://tersanocanada-my.sharepoint.com/:x:/g/personal/emmanuelj_tersano_com/Ee5L51ccOopKnxkhzGr8mOEB8z_jTj1OV4AuY5XIEMFv1Q"
                  download="Sales Entry Template"
                  style={{ textDecoration: "underline", color: "blue" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Sales Entry Template
                </a>{" "}
                for guidance.
              </Typography>
              <Box
                sx={{
                  border: "2px dashed grey",
                  borderRadius: 1,
                  textAlign: "center",
                  p: 2,
                  mt: 2,
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  {selectedFileName
                    ? `Selected file: ${selectedFileName}`
                    : "Drag and drop file to upload..."}{" "}
                </Typography>
                <IconButton
                  color="primary"
                  aria-label="upload file"
                  component="label"
                >
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    onChange={handleFileUpload}
                  />
                  <CloudUploadIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        )}

        {activeStep === 1 && (
          <Box>
            <Typography variant="h6">Step 2: CSV Summary</Typography>
            {validationErrors.length > 0 && (
              <Box sx={{ color: "red", mb: 2 }}>
                <Typography variant="body2">
                  Please fix the following errors:
                </Typography>
                <ul>
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </Box>
            )}

            {loading ? (
              <CircularProgress />
            ) : csvData.length === 0 ? (
              <Typography>No data available.</Typography>
            ) : (
              <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 300 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>SN</TableCell>
                      <TableCell>Account Type</TableCell>
                      <TableCell>Account Name</TableCell>
                      <TableCell>Account Email</TableCell>
                      <TableCell>Account Phone</TableCell>
                      <TableCell>Account Address</TableCell>
                      <TableCell>Site Contact</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.product}</TableCell>
                        <TableCell>{row.serialNumber || "N/A"}</TableCell>
                        <TableCell>{row.account.accountType}</TableCell>
                        <TableCell>{row.account.accountName}</TableCell>
                        <TableCell>{row.account.accountEmail}</TableCell>
                        <TableCell>{row.account.accountPhone}</TableCell>
                        <TableCell>{row.account.accountAddress}</TableCell>
                        <TableCell>{row.account.siteContact}</TableCell>
                        <TableCell>
                          {row.date
                            ? new Date(row.date).toLocaleDateString()
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.notes}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <StyledButton onClick={handleClose} variantType="outline-red">
          Cancel
        </StyledButton>
        {activeStep === 1 && (
          <StyledButton onClick={handleBack}>Back</StyledButton>
        )}
        {activeStep === steps.length - 1 ? (
          <StyledButton
            onClick={handleSubmit} // Use handleSubmit here
            variantType="primary"
            disabled={loading || !csvFile || validationErrors.length > 0}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </StyledButton>
        ) : (
          <StyledButton
            onClick={handleNext}
            variantType="primary"
            disabled={!csvFile || loading}
          >
            Next
          </StyledButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadCsvModal;
