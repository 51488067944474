import { create } from "zustand";
import { fetchDevices, fetchDeviceDetails } from "../services/device.service";
import { ICleanDevice } from "../common/types/DeviceTypes";
import useAuthStore from "./authStore";

const startOfDay = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
  return newDate;
};

interface DeviceState {
  allDevices: ICleanDevice[];
  selectedDevice: ICleanDevice | null;
  setSelectedDevice: (device: ICleanDevice | null) => void;
  selectedIcleanDevices: ICleanDevice[];
  setSelectedIcleanDevices: (devices: ICleanDevice[]) => void;
  dateFilter: Date | null;
  setDateFilter: (date: Date | null) => void;
  intervalFilter: "daily" | "weekly" | "monthly";
  setIntervalFilter: (interval: "daily" | "weekly" | "monthly") => void;
  fetchDevices: () => Promise<void>;
  fetchDeviceDetails: (deviceId: string) => Promise<void>;
  isLoading: boolean;
  setIsLoading: (data: boolean) => void;
}

const useDeviceStore = create<DeviceState>((set) => ({
  allDevices: [],
  selectedIcleanDevices: [],
  dateFilter: startOfDay(new Date()),
  intervalFilter: "daily",
  selectedDevice: null,
  isLoading: true,
  setIsLoading: (data: boolean) => set({ isLoading: data }),

  setSelectedIcleanDevices: (devices) =>
    set({ selectedIcleanDevices: devices }),

  setSelectedDevice: (device: ICleanDevice | null) => {
    set({ selectedDevice: device });
  },

  setDateFilter: (date) => set({ dateFilter: date }),
  setIntervalFilter: (interval) => set({ intervalFilter: interval }),

  fetchDevices: async () => {
    set({ isLoading: true });
    const { user } = useAuthStore.getState();
    if (!user) {
      console.error("User not logged in");
      return;
    }
    const devices = await fetchDevices();

    set({
      allDevices: devices as ICleanDevice[],
      selectedIcleanDevices: devices as ICleanDevice[],
    });
    set({ isLoading: false });
  },
  fetchDeviceDetails: async (deviceId: string) => {
    set({ isLoading: true });
    const device = await fetchDeviceDetails(deviceId);
    set({ selectedDevice: device as ICleanDevice });
    set({ isLoading: false });
  },
}));

export default useDeviceStore;
