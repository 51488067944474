import React, { useState, useMemo } from "react";
import {
  Autocomplete,
  Checkbox,
  TextField,
  Box,
  Typography,
  FormControl,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { SalesData } from "../../common/types/DistributorSalesTypes";
import StepperMenu from "../Menu/StepperMenu";
import dayjs from "dayjs";
import Assessment from "@mui/icons-material/Assessment";
import ImageSearch from "@mui/icons-material/ImageSearch";
import { ProductType } from "../../common/types/DistributorSalesTypes";
import { GetApp } from "@mui/icons-material";
import Papa from "papaparse";
import StyledButton from "../Tabs/StyledButton";
import useAuthStore from "../../store/authStore";

interface ReportPreviewData {
  countryName: string;
  spend: string;
  bonus: string;
  quarters: {
    Q1: number;
    Q2: number;
    Q3: number;
    Q4: number;
  };
}

interface GenerateReportProps {
  sales: SalesData[];
  onSubmit: () => void;
}

const steps = ["Select Distributors", "Report Details", "Preview & Generate"];

const GenerateSalesReport: React.FC<GenerateReportProps> = ({
  sales,
  onSubmit,
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDistributors, setSelectedDistributors] = useState<string[]>(
    []
  );
  const [reportName, setReportName] = useState<string>("");
  const [reportDescription, setReportDescription] = useState<string>("");
  const [reportType, setReportType] = useState<string>("ISS");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dataPreview, setDataPreview] = useState<ReportPreviewData[]>([]);
  const [reportGenerated, setReportGenerated] = useState<boolean>(false);

  const { user } = useAuthStore();

  // Extract unique distributors
  const getUniqueDistributors = useMemo(() => {
    const uniqueEmails = Array.from(
      new Set(sales.map((sale) => sale.distributorEmail))
    );
    return ["All", ...uniqueEmails];
  }, [sales]);

  const handleSubmit = async () => {
    try {
      const reportData = calculateISSReport(
        sales,
        selectedDistributors,
        startDate,
        endDate
      );

      if (!user) {
        console.error("User is not authenticated");
        return;
      }

      setDataPreview(reportData);
      setReportGenerated(true);
      onSubmit();
    } catch (error) {
      console.error("Error creating report:", error);
    }
  };

  const handleStepClick = (index: number) => setActiveStep(index);
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
      resetForm();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const resetForm = () => {
    setSelectedDistributors([]);
    setReportName("");
    setReportDescription("");
    setReportType("ISS");
    setStartDate(null);
    setEndDate(null);
    setDataPreview([]);
    setReportGenerated(false);
  };

  const handleCancel = () => {
    setActiveStep(0);
    resetForm();
  };

  const handleDistributorChange = (
    _event: React.ChangeEvent<{}>,
    value: string[]
  ) => {
    setSelectedDistributors(
      value.includes("All") ? getUniqueDistributors.slice(1) : value
    );
  };

  const calculateISSReport = (
    sales: SalesData[],
    distributors: string[],
    startDate: Date | null,
    endDate: Date | null
  ): ReportPreviewData[] => {
    const prices: Record<ProductType, number> = {
      [ProductType.SAO24]: 209.25,
      [ProductType.SAO4]: 285.34,
      [ProductType.LOTUS_PRO]: 837,
      [ProductType.ICLEAN_MINI]: 156.94,
      [ProductType.ICLEAN_PRO]: 309.12,
      [ProductType.ICLEAN_FLO3]: 685,
      [ProductType.NONE]: 0,
    };

    const filteredSales = sales.filter((sale) => {
      const saleDate = new Date(sale.date || "");
      return (
        distributors.includes(sale.distributorEmail) &&
        (!startDate || saleDate >= startDate) &&
        (!endDate || saleDate <= endDate)
      );
    });

    const reportData: { [country: string]: { [quarter: string]: number } } = {};
    filteredSales.forEach((sale) => {
      const country = sale.distributorCountry || "Unknown";
      const saleDate = new Date(sale.date || "");
      const quarter = Math.floor(saleDate.getMonth() / 3) + 1;
      const spend = prices[sale.product] || 0;
      if (!reportData[country])
        reportData[country] = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
      reportData[country][`Q${quarter}`] += spend;
    });

    const reportPreview: ReportPreviewData[] = Object.entries(reportData).map(
      ([countryName, quarters]) => {
        const total = quarters.Q1 + quarters.Q2 + quarters.Q3 + quarters.Q4;
        return {
          countryName,
          spend: `${total.toFixed(2)}`,
          bonus: `${(total * 0.2).toFixed(2)}`,
          quarters: {
            Q1: quarters.Q1,
            Q2: quarters.Q2,
            Q3: quarters.Q3,
            Q4: quarters.Q4,
          },
        };
      }
    );

    const totalQuarters = reportPreview.reduce(
      (acc, report) => {
        acc.Q1 += report.quarters.Q1;
        acc.Q2 += report.quarters.Q2;
        acc.Q3 += report.quarters.Q3;
        acc.Q4 += report.quarters.Q4;
        return acc;
      },
      { Q1: 0, Q2: 0, Q3: 0, Q4: 0 }
    );

    const totalSpend =
      totalQuarters.Q1 + totalQuarters.Q2 + totalQuarters.Q3 + totalQuarters.Q4;
    const totalBonus = totalSpend * 0.2;

    reportPreview.push({
      countryName: "TOTAL (EUR)",
      spend: `${totalSpend.toFixed(2)}`,
      bonus: `${totalBonus.toFixed(2)}`,
      quarters: totalQuarters,
    });

    return reportPreview;
  };

  const previewData = async () => {
    if (!reportType || !startDate || !endDate) {
      console.warn("Start date, end date, or report type is not set.");
      return;
    }

    let previewData: ReportPreviewData[] = [];

    if (reportType === "ISS") {
      previewData = calculateISSReport(
        sales,
        selectedDistributors,
        startDate,
        endDate
      );
    } else {
      console.warn("Invalid report type selected");
      return;
    }

    setDataPreview(previewData);
  };

  const handleDownload = () => {
    if (dataPreview.length === 0) {
      console.warn("No data available to download");
      return;
    }

    const csvData = dataPreview.map((item) => ({
      "Country Name": item.countryName,
      "Q1 (EUR)": item.quarters.Q1.toFixed(2),
      "Q2 (EUR)": item.quarters.Q2.toFixed(2),
      "Q3 (EUR)": item.quarters.Q3.toFixed(2),
      "Q4 (EUR)": item.quarters.Q4.toFixed(2),
      "Total (EUR)": parseFloat(item.spend.replace(/[^\d.-]/g, "")).toFixed(2),
      "Bonus (EUR)": parseFloat(item.bonus.replace(/[^\d.-]/g, "")).toFixed(2),
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Sales_Report_${new Date().getFullYear()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Autocomplete
              multiple
              options={getUniqueDistributors}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              value={selectedDistributors}
              onChange={handleDistributorChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Distributors"
                  placeholder="Select distributors"
                />
              )}
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6">Report Details</Typography>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Report Name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value)}
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Description"
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
                multiline
                rows={2}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Report Type</InputLabel>
              <Select
                value={reportType}
                onChange={(e) => setReportType(e.target.value as string)}
                label="Report Type"
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="ISS">ISS</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="normal">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Year"
                  views={["year"]}
                  value={dayjs(startDate)}
                  onChange={(date) => {
                    const selectedYear = date ? date.year() : null;
                    const newStartDate = selectedYear
                      ? new Date(selectedYear, 0, 1)
                      : null;
                    const newEndDate = selectedYear
                      ? new Date(selectedYear, 11, 31)
                      : null;
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 1.5,
                mb: 1.5,
              }}
            >
              <StyledButton
                onClick={previewData}
                icon={<ImageSearch />}
                iconColor={theme.palette.primary.main}
              >
                Preview Data
              </StyledButton>
              <StyledButton
                onClick={handleSubmit}
                icon={<Assessment />}
                iconColor={theme.palette.primary.main}
                disabled={reportGenerated}
              >
                Generate Report
              </StyledButton>
              <StyledButton
                onClick={handleDownload}
                disabled={!dataPreview}
                icon={<GetApp />}
                iconColor={theme.palette.primary.main}
              >
                Download Report
              </StyledButton>
            </Box>
            {dataPreview.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Country Name</TableCell>
                      <TableCell>Q1 (EUR)</TableCell>
                      <TableCell>Q2 (EUR)</TableCell>
                      <TableCell>Q3 (EUR)</TableCell>
                      <TableCell>Q4 (EUR)</TableCell>
                      <TableCell>Total (EUR)</TableCell>
                      <TableCell>Bonus (EUR)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataPreview.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.countryName}</TableCell>
                        <TableCell>{`€${item.quarters.Q1.toFixed(
                          2
                        )}`}</TableCell>
                        <TableCell>{`€${item.quarters.Q2.toFixed(
                          2
                        )}`}</TableCell>
                        <TableCell>{`€${item.quarters.Q3.toFixed(
                          2
                        )}`}</TableCell>
                        <TableCell>{`€${item.quarters.Q4.toFixed(
                          2
                        )}`}</TableCell>
                        <TableCell>{item.spend}</TableCell>
                        <TableCell>{item.bonus}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box>
      <StepperMenu
        steps={steps}
        activeStep={activeStep}
        handleStepClick={handleStepClick}
        handleBack={handleBack}
        handleNext={handleNext}
        handleCancel={handleCancel}
        disableNext={
          (activeStep === 0 && selectedDistributors.length === 0) ||
          (activeStep === 1 && reportName === "") ||
          (activeStep === 1 && reportDescription === "") ||
          (activeStep === 1 && reportType === "") ||
          (activeStep === 1 && startDate === null && endDate === null)
        }
        disableFinish={false}
      >
        {getStepContent(activeStep)}
      </StepperMenu>
    </Box>
  );
};

export default GenerateSalesReport;
