import axios from "axios";
import { CustomerCreationPayload } from "../common/types/UserTypes";
import { Customer } from "../store/useCustomer";
import useAuthStore from "../store/authStore";

const API_URL = process.env.REACT_APP_API_URL;

export const createCustomer = async (payload: CustomerCreationPayload) => {
  try {
    const response = await axios.post(
      `${API_URL}/auth/customer/signup`,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};
export const addCustomer = async (customer: {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  distributorEmail: string;
  customerGroup?: string;
}) => {
  try {
    const response = await axios.post(`${API_URL}/customer/add`, {
      ...customer,
    });
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

export const fetchCustomers = async (): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/customer/all`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch customers:", error);
    throw new Error("Failed to fetch customers");
  }
};

export const addDevicesToCustomer = async (
  customerId: string,
  deviceIds: string[]
) => {
  try {
    const response = await axios.post(
      `${API_URL}/customer/${customerId}/devices`,
      deviceIds
    );
    return response.data;
  } catch (error: any) {
    console.error(error.message);
    throw error;
  }
};

// API function to retrieve a customer by email
export const fetchCustomerByEmail = async (
  email: string
): Promise<Customer> => {
  try {
    console.log(email);
    const response = await axios.get(`${API_URL}/customer/email`, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch customer by email:", error);
    throw new Error("Failed to fetch customer");
  }
};

export const addAdditionalCustomersToDevice = async (
  deviceId: string,
  customers: { email: string; firstName: string; lastName: string }[]
) => {
  try {
    const response = await axios.post(
      `${API_URL}/customer/device/customers/add`,
      {
        deviceId,
        customers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to add additional users to device:", error);
    throw new Error("Failed to add additional users to device");
  }
};

export const removeAdditionalCustomerFromDevice = async (
  deviceId: string,
  customerEmail: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/customer/device/customers/remove`,
      {
        deviceId,
        customerEmail,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to remove additional customer from device:", error);
    throw new Error("Failed to remove additional customer from device");
  }
};

export const fetchCustomersByEmails = async (
  emails: string[]
): Promise<{ customers: any[] }> => {
  try {
    const response = await axios.post(`${API_URL}/customer/emails`, { emails });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch customers by emails:", error);
    throw new Error("Failed to fetch customers");
  }
};

export const fetchCustomersForUser = async (): Promise<any[]> => {
  try {
    const token = useAuthStore.getState().getAuthToken();
    const response = await axios.get(`${API_URL}/customer/fetch-customers`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch customers for user:", error);
    throw new Error("Failed to fetch customers");
  }
};

// Service function to create a new group
export const createGroup = async (
  groupFirstName: string,
  groupLastName: string,
  email: string,
  password: string,
  users: string[] = [], // Only emails are needed
  distributor: string
) => {
  try {
    const response = await axios.post(`${API_URL}/auth/group/signup`, {
      firstName: groupFirstName,
      lastName: groupLastName,
      email,
      password,
      users,
      distributor,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to create group:", error);
    throw new Error("Failed to create group");
  }
};

// Service function to fetch all groups
export const fetchGroups = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_URL}/group/all`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch groups:", error);
    throw new Error("Failed to fetch groups");
  }
};

// Service function to fetch groups by distributor
export const fetchGroupsByDistributor = async (
  distributor: string
): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_URL}/group/distributor`, {
      params: { distributor },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch groups by distributor:", error);
    throw new Error("Failed to fetch groups by distributor");
  }
};

// Service function to update users in a group (add or remove)

// Service function to rename a group
export const renameGroup = async (groupId: string, name: string) => {
  try {
    const response = await axios.patch(`${API_URL}/group/${groupId}/rename`, {
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to rename group:", error);
    throw new Error("Failed to rename group");
  }
};

export const updateGroupUsers = async (
  customerGroupEmail: string,
  customerEmails: string[] // Updated to only accept an array of email strings
) => {
  try {
    // Make a PATCH request to update the group users
    const response = await axios.post(`${API_URL}/customer/group/assign`, {
      customerEmails, // Send the 'users' array as the payload
      customerGroupEmail,
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Failed to update users in group:",
      error.response?.data || error.message
    );
    throw new Error("Failed to update users in group");
  }
};

export const deleteCustomer = async (customerId: string) => {
  try {
    const response = await axios.delete(`${API_URL}/customer/${customerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
