import { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { Label, Slider, SliderProps } from "@fluentui/react-components";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import StyledIconButton from "../../../components/Tabs/StyledIconButton";
import CheckIcon from "@mui/icons-material/Check";
import { Dayjs } from "dayjs";

export default function AutoCycleComponent() {
  const [flushTime, setflushTime] = useState(5);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [flushType, setFlushType] = useState<string>("");

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const onSlideChange: SliderProps["onChange"] = (_, data) =>
    setflushTime(data.value);

  const handleFlushTypeChange = (event: SelectChangeEvent<string>) => {
    setFlushType(event.target.value as string);
  };

  const handleSave = () => {
    if (!selectedDate) {
      alert("Please select a date and time.");
      return;
    }

    if (!flushType) {
      alert("Please select a flush type.");
      return;
    }

    const payload = {
      dateTime: selectedDate.toISOString(),
      flushType,
      duration: flushTime,
    };

    console.log("Payload to send to backend:", payload);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        padding: 2,
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent={isMobile ? "center" : "space-between"}
        pb={2}
        flexDirection={isMobile ? "column" : "row"}
        gap={1}
      >
        <Typography variant="h6" fontWeight="bold" textAlign="center">
          Auto Cycle
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            disablePast
            label="Select a date..."
            views={["year", "day", "hours", "minutes", "seconds"]}
            sx={{
              "& .MuiInputBase-root": {
                padding: 1,
                fontSize: "14px",
                height: "32px",
              },
            }}
          />
        </LocalizationProvider>
      </Box>

      {/* Content */}
      <Box
        mt={2}
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "space-around",
          gap: isMobile ? 2 : 1,
        }}
      >
        <FormControl sx={{ minWidth: isMobile ? "100%" : "40%" }}>
          <InputLabel>Flush Type</InputLabel>
          <Select
            label="Flush Type"
            variant="outlined"
            fullWidth
            value={flushType}
            onChange={handleFlushTypeChange}
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="forward">Forward</MenuItem>
            <MenuItem value="backflush">Backflush</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Label>Set Duration: {flushTime} min</Label>
          <Slider
            value={flushTime}
            min={0}
            max={30}
            onChange={onSlideChange}
            style={{ width: isMobile ? "100%" : "auto" }}
          />
        </Box>
      </Box>

      {/* Actions */}
      <Box
        mt={2}
        display="flex"
        justifyContent={isMobile ? "center" : "flex-end"}
      >
        <Box>
          <StyledIconButton onClick={handleSave}>
            <CheckIcon color="primary" />
          </StyledIconButton>
        </Box>
      </Box>
    </Paper>
  );
}
