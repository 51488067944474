import React, { useEffect, useState } from "react";
import { Typography, Paper } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";

import { getICleanReplacementHistory } from "../../services/device.service";
import { ICleanDevice } from "../../common/types/DeviceTypes";

interface ReplacementHistoryTimelineProps {
  deviceId: string;
}

const ReplacementHistoryTimeline: React.FC<ReplacementHistoryTimelineProps> = ({
  deviceId,
}) => {
  const [history, setHistory] = useState<ICleanDevice[]>([]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const result = await getICleanReplacementHistory(deviceId);
        if (result.data) {
          setHistory(result.data);
        }
      } catch (error) {
        console.error("Failed to fetch replacement history:", error);
        setHistory([]);
      }
    };

    fetchHistory();
  }, [deviceId]);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: "6px 16px",
        margin: "16px",
        border: "1px solid #bbb",
        borderRadius: 0,
      }}
    >
      <Timeline position="alternate">
        {history.length ? (
          history.map((item, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent>
                <Typography color="textSecondary">
                  {item.replacementHistory &&
                    new Date(
                      item.replacementHistory?.replacedDate
                    ).toLocaleDateString()}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator sx={{ height: 120 }}>
                <TimelineDot color="primary" variant="outlined" />
                {index < history.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" component="span">
                  {item.deviceInformation.deviceName} (
                  {item.deviceInformation.serial})
                </Typography>
                <Typography>
                  Reason:{" "}
                  {item.replacementHistory?.reasonForReplacement || "N/A"}
                </Typography>
                <Typography>
                  Location:{" "}
                  {(item.deviceLocation && item.deviceLocation.country) || ""},{" "}
                  {(item.deviceLocation && item.deviceLocation.city) || ""},{" "}
                  {(item.deviceLocation && item.deviceLocation.state) || ""},{" "}
                  {(item.deviceLocation && item.deviceLocation.address) || ""}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))
        ) : (
          <Typography>No replacement history found.</Typography>
        )}
      </Timeline>
    </Paper>
  );
};

export default ReplacementHistoryTimeline;
