import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  addInstallerToDistributor,
  fetchSubDistributors,
} from "../../services/distributor.service";
import useAuthStore from "../../store/authStore";
import StyledButton from "../Tabs/StyledButton";

const AddInstallerModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onSnackbarChange: (snackbarData: {
    open: boolean;
    message: string;
    severity: "success" | "error";
  }) => void;
}> = ({ open, onClose, onSnackbarChange, onSuccess }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [distributorType, setDistributorType] = useState("myself");
  const [error, setError] = useState<string | null>(null);
  const [subDistributors, setSubDistributors] = useState<any[]>([]);
  const [selectedSubDistributor, setSelectedSubDistributor] = useState<any>();

  const { user } = useAuthStore();

  useEffect(() => {
    const fetchSubDistributorsData = async () => {
      if (!user?._id) return;

      try {
        const subDistributorsData = await fetchSubDistributors();
        setSubDistributors(subDistributorsData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSubDistributorsData();
  }, [user]);

  const handleSubmit = async () => {
    if (!firstName || !lastName || !email) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      const installer = { email, firstName, lastName };
      if (distributorType === "distributor") {
        if (!selectedSubDistributor) {
          setError("Please select a sub distributor.");
          return;
        }
        var distributorEmail = selectedSubDistributor.email;
        await addInstallerToDistributor(distributorEmail, installer);
        onSnackbarChange({
          open: true,
          message: "Installer added for sub distributor successfully",
          severity: "success",
        });
        onSuccess();
      } else {
        await addInstallerToDistributor(user?.email ?? "", installer);
        onSnackbarChange({
          open: true,
          message: "Installer added successfully",
          severity: "success",
        });
        onSuccess();
      }
      setFirstName("");
      setLastName("");
      setEmail("");
      setDistributorType("myself");
      onClose();
    } catch (err) {
      onSnackbarChange({
        open: true,
        message: "Failed to add installer. Please try again.",
        severity: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Installer</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Default Password: <strong>Password@321</strong>
        </Typography>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <TextField
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          variant="outlined"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setError(null);
          }}
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          variant="outlined"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setError(null);
          }}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(null);
          }}
        />
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel>Distributor Type</InputLabel>
          <Select
            value={distributorType}
            onChange={(e) => setDistributorType(e.target.value as string)}
            label="Distributor Type"
          >
            <MenuItem value="myself">Installer for Myself</MenuItem>
            <MenuItem value="distributor">
              Installer for Sub Distributor
            </MenuItem>
          </Select>
        </FormControl>
        {distributorType === "distributor" && (
          <FormControl fullWidth variant="outlined" margin="dense">
            <Autocomplete
              options={subDistributors}
              getOptionLabel={(option) => option.email}
              value={selectedSubDistributor}
              onChange={(_event, value) => setSelectedSubDistributor(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Sub Distributor"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={onClose}>Cancel</StyledButton>
        <StyledButton onClick={handleSubmit} variantType="primary">
          Add Installer
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddInstallerModal;
