import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Chip,
  AlertColor,
  Alert,
  Snackbar,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import CreateNewDistributorModal from "./CreateNewDistributorModal";
import useDistributorStore from "../../store/useDistributor";
import StyledButton from "../Tabs/StyledButton";

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Created Time",
    flex: 1,
    minWidth: 180,
    valueFormatter: (value) => new Date(value).toLocaleString(),
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "country",
    headerName: "Country",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "state",
    headerName: "State",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1.5,
    minWidth: 200,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    minWidth: 130,
    valueFormatter: (value: string) =>
      value.toLowerCase() === "unknown" ? "-" : value,
  },
  { field: "email", headerName: "Email", flex: 1.5, minWidth: 225 },
  {
    field: "isActive",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <Chip
        size="small"
        label={params.value ? "Active" : "Inactive"}
        color={params.value ? "success" : "error"}
      />
    ),
  },
];

const DistributorTable: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { Distributors, fetchDistributors } = useDistributorStore();
  const [selected, setSelected] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({ open: false, message: "", severity: undefined });

  useEffect(() => {
    fetchDistributors();
  }, [fetchDistributors]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleDistributorCreationSuccess = () => {
    setSnackbar({
      open: true,
      message: "Distributor Created successfully created.",
      severity: "success",
    });
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  return (
    <Box p={0}>
      <Box mt={0} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: { xs: "95%", md: "96%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
              mb: 2,
            }}
          >
            <Typography sx={{ ml: 1 }} variant={isMobile ? "subtitle1" : "h6"}>
              Manage Your Distributors
            </Typography>
            <StyledButton
              onClick={handleOpenModal}
              variantType="primary"
              icon={<Add />}
            >
              Add Distributor
            </StyledButton>
          </Box>
          <DataGrid
            rows={Distributors}
            columns={columns}
            getRowId={(row) => row._id}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "700",
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelection) => {
              setSelected(newSelection.map((id) => String(id)));
            }}
            rowSelectionModel={selected}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1.5,
            backgroundColor: "#f5f5f5",
            mb: 1,
          }}
        >
          <CreateNewDistributorModal
            open={modalOpen}
            onClose={handleCloseModal}
            onSuccess={handleDistributorCreationSuccess}
            onError={handleError}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box >
  );
};

export default DistributorTable;
