import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ButtonGroup,
  Snackbar,
  Alert,
} from "@mui/material";
import AssignCustomersToDevice from "./AssignCustomersToDevice";
import AssignDevicesToCustomer from "./AssignDevicesToCustomer";
import { Customer } from "../../store/useCustomer";
import { DeviceData } from "../../common/types/DeviceTypes";
import StyledButton from "../Tabs/StyledButton";

type BulkAssignModalProps = {
  open: boolean;
  onClose: () => void;
  devices: DeviceData[];
  customers: Customer[];
};

export default function BulkAssignModal({
  open,
  onClose,
  devices,
  customers,
}: BulkAssignModalProps) {
  const [mode, setMode] = useState<"customer" | "device" | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (!open) {
      setMode(null);
    }
  }, [open]);

  const handleModeSelect = (selectedMode: "customer" | "device") => {
    setMode(selectedMode);
  };

  const handleClose = () => {
    setMode(null);
    onClose();
  };

  const handleSnackbarClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAssignmentSuccess = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Bulk Assignment</DialogTitle>
        <DialogContent>
          {!mode ? (
            <ButtonGroup fullWidth>
              <StyledButton
                variantType="outline-primary"
                onClick={() => handleModeSelect("device")}
              >
                Assign Customers to Device
              </StyledButton>
              <StyledButton
                variantType="outline-primary"
                onClick={() => handleModeSelect("customer")}
              >
                Assign Devices to Customer
              </StyledButton>
            </ButtonGroup>
          ) : mode === "device" ? (
            <AssignCustomersToDevice
              devices={devices}
              customers={customers}
              onClose={handleClose}
              onSuccess={handleAssignmentSuccess}
            />
          ) : (
            <AssignDevicesToCustomer
              devices={devices}
              customers={customers}
              onSuccess={handleAssignmentSuccess}
            />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
