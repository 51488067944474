import { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  AppBar,
  Toolbar,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DevicesIcon from "@mui/icons-material/Devices";
import SettingsIcon from "@mui/icons-material/Settings";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import logoWhite from "../assets/images/tersanowhite.png";

const DeviceControlPage = () => {
  const [relayState, setRelayState] = useState<boolean[][]>([
    [false, false, false, false], // Relay states for Device 1
    [false, false, false, false], // Relay states for Device 2
    [false, false, false, false], // Relay states for Device 3
    [false, false, false, false], //
    [false, false, false, false], //
    [false, false, false, false], //
    [false, false, false, false], //
  ]);

  // Handle toggling relay buttons for devices
  const handleRelayToggle = (deviceIndex: number, relayIndex: number) => {
    const updatedRelayState = [...relayState];
    updatedRelayState[deviceIndex][relayIndex] =
      !updatedRelayState[deviceIndex][relayIndex];
    setRelayState(updatedRelayState);
  };

  // Mock device data for display
  const devices = [
    { id: 1, name: "Device 1", status: "Active" },
    { id: 2, name: "Device 2", status: "Active" },
    { id: 3, name: "Device 3", status: "Inactive" },
    { id: 4, name: "Device 4", status: "Inactive" },
    { id: 5, name: "Device 5", status: "Inactive" },
    { id: 6, name: "Device 6", status: "Inactive" },
  ];

  // Sidebar content with icons
  const sidebarContent = (
    <List>
      <ListItem button sx={{ mb: 2 }}>
        <Tooltip title="Dashboard">
          <ListItemIcon>
            <DashboardIcon sx={{ color: "white" }} />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <ListItem button sx={{ mb: 2 }}>
        <Tooltip title="All Devices">
          <ListItemIcon>
            <DevicesIcon sx={{ color: "white" }} />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <ListItem button>
        <Tooltip title="Settings">
          <ListItemIcon>
            <SettingsIcon sx={{ color: "white" }} />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
    </List>
  );

  return (
    <Box sx={{ display: "flex", minHeight: "100vh", background: "#111" }}>
      {/* App Bar - IClean Lines heading */}
      <AppBar position="fixed" sx={{ background: "#1c1c1c" }}>
        <Toolbar>
          <Tooltip title="Back to Dashboard">
            <Box
              sx={{
                position: "absolute",
                top: { xs: 18, sm: 22 },
                left: 20,
                border: "1px solid white",
                p: 0.1,
              }}
            >
              <img
                src={logoWhite}
                alt="logo"
                height={12}
              // style={{ magrin: 30 }}
              />
            </Box>
          </Tooltip>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, pl: { sm: 10, xs: 15 } }}
          >
            ICleanLines Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: { xs: 40, sm: 60 },
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: { xs: 50, sm: 60 },
            background: "linear-gradient(45deg, black, black)",
            color: "white",
            top: "64px", // Sidebar starts below the app bar
            borderRight: "1px solid #1c1c1c",
            pl: { xs: 0, sm: 1.5 },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 64px)", // Sidebar fills the remaining height
          }}
        >
          <List>{sidebarContent}</List>
        </Box>
      </Drawer>

      {/* Main Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "64px", // Push content below the app bar
          background: "linear-gradient(135deg, black, #2c2c2c)", // Futuristic gradient background
        }}
      >
        {/* Device Cards */}
        <Grid container spacing={3}>
          {devices.map((device, deviceIndex) => (
            <Grid item xs={12} sm={6} md={4} key={device.id}>
              <Paper
                elevation={5}
                sx={{
                  p: 3,
                  background: "linear-gradient(135deg, #1c1c1c, #2c2c2c)",
                  color: "white",
                  borderRadius: 0,
                  position: "relative",
                  textAlign: "center",
                }}
              >
                {/* Device Details */}
                <Typography variant="h6" gutterBottom>
                  {device.name}
                </Typography>
                <Typography variant="body2" color="gray" gutterBottom>
                  Status: {device.status}
                </Typography>

                {/* Device Illustration */}
                <Box
                  sx={{
                    my: 2,
                    height: "100px",
                    background: "linear-gradient(45deg, #3a3a3a, #555555)",
                    borderRadius: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" color="white">
                    Device Image/Illustration
                  </Typography>
                </Box>

                {/* Relay Buttons - Four buttons for each device */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    mt: 2,
                  }}
                >
                  {[0, 1, 2, 3].map((relayIndex) => (
                    <Box key={relayIndex} sx={{ textAlign: "center" }}>
                      <Typography variant="body2" color="gray">
                        Relay {relayIndex + 1}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          handleRelayToggle(deviceIndex, relayIndex)
                        }
                        sx={{
                          color: relayState[deviceIndex][relayIndex]
                            ? "#4caf50"
                            : "#f44336",
                        }}
                      >
                        {relayState[deviceIndex][relayIndex] ? (
                          <ToggleOnIcon sx={{ fontSize: 40 }} />
                        ) : (
                          <ToggleOffIcon sx={{ fontSize: 40 }} />
                        )}
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default DeviceControlPage;
