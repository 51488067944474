import React, { useState, useMemo } from "react";
import { Card, CardContent, Typography, Box, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ICleanDevice } from "../../../common/types/DeviceTypes";
import dayjs from "dayjs";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type AverageUsageCardProps = {
  selectedDevices: ICleanDevice[];
  color: string;
  isGallons?: boolean;
  intervalFilter: "daily" | "weekly" | "monthly";
};

// Add this function at the top of the file, outside the component
function formatNumber(value: number, decimals: number = 2): string {
  return value.toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export default function AverageUsageCard({
  selectedDevices,
  color,
  isGallons,
  intervalFilter,
}: AverageUsageCardProps) {
  const [highestUsage, setHighestUsage] = useState(0);
  const theme = useTheme();

  const calculateAverageUsage = (): number => {
    let totalLiters = 0;
    let count = 0;

    // Loop through selected devices and their usage
    selectedDevices.forEach((device) => {
      device.deviceUsage.forEach((usage: any) => {
        totalLiters += usage.litersDispensed;
        count++;
      });
    });

    // Calculate average based on the selected interval
    let averageUsage = 0;
    if (count > 0) {
      if (intervalFilter === "daily") {
        averageUsage = totalLiters / count;
      } else if (intervalFilter === "weekly") {
        averageUsage = (totalLiters / count) * 7; // Weekly average
      } else if (intervalFilter === "monthly") {
        averageUsage = (totalLiters / count) * 30; // Monthly average
      }
    }
    if (averageUsage > highestUsage) {
      setHighestUsage(averageUsage); // Update the highest observed usage
    }

    return averageUsage;
  };

  const averageUsage = calculateAverageUsage();

  const calculateChartData = useMemo(() => {
    const now = dayjs();
    const chartData = [];

    for (let i = 6; i >= 0; i--) {
      let periodStart: dayjs.Dayjs, periodEnd: dayjs.Dayjs;
      if (intervalFilter === "daily") {
        periodStart = now.subtract(i, "day").startOf("day");
        periodEnd = periodStart.endOf("day");
      } else if (intervalFilter === "weekly") {
        periodStart = now.subtract(i, "week").startOf("week");
        periodEnd = periodStart.endOf("week");
      } else {
        // monthly
        periodStart = now.subtract(i, "month").startOf("month");
        periodEnd = periodStart.endOf("month");
      }

      let totalLiters = 0;
      let count = 0;

      selectedDevices.forEach((device) => {
        device.deviceUsage.forEach((usage) => {
          const usageDate = dayjs(usage.usageTimestamp);
          if (usageDate.isAfter(periodStart) && usageDate.isBefore(periodEnd)) {
            totalLiters += usage.litersDispensed;
            count++;
          }
        });
      });

      const averageUsage = count > 0 ? totalLiters / count : 0;
      chartData.push({
        averageUsage,
        periodStart,
        periodEnd,
      });
    }

    return chartData;
  }, [selectedDevices, intervalFilter]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      y: { display: false },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const value = context.parsed.y;
            const unit = isGallons ? "Gal" : "L";
            const convertedValue = isGallons ? value / 3.78541 : value;
            return `Avg. Usage: ${formatNumber(convertedValue)} ${unit}`;
          },
          title: function (context: any) {
            const dataPoint = calculateChartData[context[0].dataIndex];
            if (intervalFilter === "daily") {
              return dataPoint.periodStart.format("MMMM D, YYYY");
            } else if (intervalFilter === "weekly") {
              return `${dataPoint.periodStart.format(
                "MMM D"
              )} - ${dataPoint.periodEnd.format("MMM D, YYYY")}`;
            } else {
              // monthly
              return dataPoint.periodStart.format("MMMM YYYY");
            }
          },
        },
      },
    },
  };

  const data = {
    labels: calculateChartData.map((d) => d.periodStart.format("MMM D")),
    datasets: [
      {
        label: "Average Usage",
        data: calculateChartData.map((d) => d.averageUsage),
        borderColor: color,
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
    ],
  };

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "8px",
        border: "1px solid #d1d1d1",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
      }}
    >
      <CardContent sx={{ p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              variant="h5"
              component="h4"
              fontWeight="700"
              sx={{ color: "black" }}
            >
              {isGallons ? (
                <>
                  {formatNumber(averageUsage / 3.78541)} <br /> Gallons
                </>
              ) : (
                <>
                  {formatNumber(averageUsage)} <br /> Liters
                </>
              )}
            </Typography>
            <Box display="flex" flexDirection="column" mt={1}>
              <Typography
                variant="subtitle2"
                sx={{
                  display: "inline-block",
                  width: "fit-content",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
              >
                {intervalFilter === "daily"
                  ? "Daily Average"
                  : intervalFilter === "weekly"
                  ? "Weekly Average"
                  : "Monthly Average"}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" mt={0.5}>
                for {selectedDevices.length} Devices
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: "64px", width: "128px", marginLeft: "8px" }}>
            <Line data={data} options={options} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
