import React, { useState } from "react";
import { Modal, Box, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WifiIcon from "@mui/icons-material/Wifi";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import StyledIconButton from "../Tabs/StyledIconButton";

const WiFiSetup: React.FC = () => {
  const [portalModalOpen, setPortalModalOpen] = useState(false);
  const [networkModalOpen, setNetworkModalOpen] = useState(false);
  const theme = useTheme();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        mb: 3,
      }}
    >
      <Box
        textAlign="center"
        bgcolor="background.default"
        color="primary.contrastText"
        onClick={() => setNetworkModalOpen(true)}
        sx={{
          cursor: "pointer",
          mb: 2,
          py: 2,
          border: "1px solid rgba(0, 0, 0, 0.13)",
          width: { xs: "90%", sm: "50%", md: "50%" },
          "&:hover": {
            backgroundColor: "rgba(30, 58, 138, 1)",
            "& .MuiTypography-root": { color: "white" },
            "& .MuiIconButton-root": { color: "white" },
            "& .MuiSvgIcon-root": {
              fill: "white",
            },
            transform: "scale(1.01)",
          },
        }}
      >
        <WifiIcon sx={{ fontSize: 30, color: "grey.800" }} />{" "}
        <Typography
          variant="body1"
          sx={{ color: "black", fontWeight: 500, fontSize: "13px" }}
        >
          WiFi
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ color: "grey.800" }}
        >
          Give Device Access to Wifi
        </Typography>
      </Box>

      <Modal open={portalModalOpen} onClose={() => setPortalModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "70%", md: "40%" },
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: { xs: 2, sm: 3, md: 3 },

            borderRadius: 1,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                color: "grey.800",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              Click or Copy the on the link below while connected to the device
              in your browser
            </Typography>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => setPortalModalOpen(false)}
            >
              <CloseIcon />
            </StyledIconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "14px",
              color: "grey.800",
            }}
          >
            <a
              href="http://192.168.4.1/setup"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tersano.local/setup
            </a>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => copyToClipboard("http://tersano.local/setup")}
              sx={{ ml: 1 }}
            >
              <FileCopyIcon />
            </StyledIconButton>
          </Typography>
        </Box>
      </Modal>

      <Modal open={networkModalOpen} onClose={() => setNetworkModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "70%", md: "40%" },
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "#f5f5f5",
            p: { xs: 2, sm: 3, md: 3 },
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Follow Instructions
            </Typography>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => setNetworkModalOpen(false)}
            >
              <CloseIcon />
            </StyledIconButton>
          </Box>
          <Box
            sx={{
              width: "90%",
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              1. Open the WiFi settings on your phone/laptop/tablet
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              2. Search for the Unit - LotusPro-(SerialNumber)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              3. Connect to the Unit and enter password 123456789
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              4. Open Browser on Device and type "tersano.local" or
              "192.168.4.1"
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
              }}
            >
              5. Connect device to WiFi. After successful connection, wait 6-10
              seconds and click Next.
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "14px",
              color: "grey.800",
              mt: 2,
            }}
          >
            <a
              href="http://192.168.4.1/wifi"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tersano.local/wifi
            </a>
            <StyledIconButton
              variantType="transparent"
              iconColor={theme.palette.primary.main}
              onClick={() => copyToClipboard("http://192.168.4.1/wifi")}
              sx={{ ml: 1 }}
            >
              <FileCopyIcon />
            </StyledIconButton>
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default WiFiSetup;
