import React, { useState, useEffect } from "react";
import { Box, Grid, Stack, Tabs, useTheme } from "@mui/material";
import dayjs from "dayjs";
import IcleanLinesDeviceTable from "./Charts/IcleanLinesDeviceTable";
import ICleanLinesMetricGraph from "./Charts/iCleanLinesMetricGraph";
import TimeDynamicGraph from "./Charts/TimeDynamicGraph";
import useICleanLinesStore from "../../../store/useICleanLinesStore";
import TotalUsageCard from "./Charts/TotalUsageCard";
import AutoCycle from "./AutoCycle";
import {
  makeStyles,
  Button,
  Select,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Divider,
} from "@fluentui/react-components";
import type { SelectProps } from "@fluentui/react-components";
import {
  DataBarVerticalAscendingFilled,
  ArrowClockwiseFilled,
  Dismiss24Regular,
  TreeEvergreen20Filled,
} from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import StyledButton from "../../../components/Tabs/StyledButton";
import StyledIconButton from "../../../components/Tabs/StyledIconButton";
import useAuthStore from "../../../store/authStore";
import PlainTab from "../../../components/Tabs/PlainTab";
import OldICleanLinesDashboard from "../../../components/Product/OldCleanlinesDashboard";

interface TabConfig {
  label: string;
  view: string;
  roles?: string[]; // Optional array of roles that can see this tab
}

const TAB_CONFIGURATIONS = {
  iCleanLines: [
    {
      label: "Icleanlines Old",
      view: "icleanlinesOld",
      roles: ["Admin", "SuperAdmin"] as string[],
    },
    {
      label: "iCleanLines",
      view: "icleanlines",
    },
  ],
} as const;

const useStyles = makeStyles({
  control: {
    maxWidth: "175px",
  },
  drawerInputs: {
    width: "100%",
    marginBottom: "14px",
  },
  sustainabilityIconTextAlign: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  treeIcon: {
    color: "#2E7D32",
  },
  drawerDividerMargin: {
    margin: "14px 0",
  },
});

const ICleanLinesDashboard: React.FC = () => {
  const {
    iCleanLinesDevices,
    selectedIcleanLinesDevices,
    fetchICleanLinesDevices,
    dateFilter,
    setDateFilter,
    intervalFilter,
    setIntervalFilter,
  } = useICleanLinesStore();

  const [selectedTab, setSelectedTab] = useState<number>(1); // Set default to 1 (Lotus Pro)
  const [viewMode, setViewMode] = useState<string>("icleanlines"); // Set default to "icleanlines"
  const [isGallons, setIsGallons] = useState(false);
  const [selectedBarDate, setSelectedBarDate] = useState<Date | null>(null);
  const [selectedInterval, setSelectedInterval] = useState<string>("daily");
  const [isReportDrawerOpen, setIsReportDrawerOpen] = useState<boolean>(false);
  const [selectedReportInterval, setSelectedReportInterval] =
    useState<string>("daily");

  useEffect(() => {
    fetchICleanLinesDevices();
    setDateFilter(new Date()); // Initialize date filter
  }, [fetchICleanLinesDevices, setDateFilter]);

  const getAvailableTabs = (): TabConfig[] => {
    return TAB_CONFIGURATIONS.iCleanLines.filter((tab) => hasAccess(tab));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const availableTabs = getAvailableTabs();
    if (availableTabs[newValue]) {
      setSelectedTab(newValue);
      setViewMode(availableTabs[newValue].view);
    }
  };

  // const handleBarClick = (date: Date) => setSelectedBarDate(date);
  const { user, userRole } = useAuthStore();

  const theme = useTheme();
  const styles = useStyles();

  const selectedDate =
    intervalFilter === "daily" && selectedBarDate
      ? dayjs(selectedBarDate)
      : dayjs(dateFilter);

  const toggleReportDrawer = (newOpen: boolean) => () => {
    setIsReportDrawerOpen(newOpen);
  };

  const handleReportIntervalChange: SelectProps["onChange"] = (
    event: React.ChangeEvent<HTMLSelectElement>,
    data: { value: string }
  ) => {
    const newInterval = data.value as "daily" | "weekly" | "monthly";
    setSelectedReportInterval(newInterval);
  };

  const hasAccess = (tab: TabConfig): boolean => {
    if (!tab.roles) return true;
    return !!user && tab.roles.includes(user.role);
  };

  const [unit, setUnit] = useState<"liters" | "gallons">("liters");

  const handleUnitChange: SelectProps["onChange"] = (
    event: React.ChangeEvent<HTMLSelectElement>,
    data: { value: string }
  ) => {
    const newValue = data.value as "liters" | "gallons";
    setUnit(newValue);
    setIsGallons(newValue === "gallons");
  };

  const handleIntervalChange: SelectProps["onChange"] = (
    event: React.ChangeEvent<HTMLSelectElement>,
    data: { value: string }
  ) => {
    const newInterval = data.value as "daily" | "weekly" | "monthly";
    setSelectedInterval(newInterval);
    setIntervalFilter(newInterval);
    setSelectedBarDate(null);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    const availableTabs = getAvailableTabs();
    let defaultTabIndex = 1;

    defaultTabIndex = availableTabs.findIndex(
      (tab) => tab.view === "dashboard"
    );

    defaultTabIndex = defaultTabIndex >= 1 ? defaultTabIndex : 1;
    setSelectedTab(defaultTabIndex);
    if (availableTabs[defaultTabIndex]) {
      setViewMode(availableTabs[defaultTabIndex].view);
    }
  }, [userRole]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 0 10px 0",
          overflowX: "auto",
          gap: 2,
        }}
      >
        {/* Tabs Section */}
        <Box
          sx={{
            flexShrink: 0,
            overflowX: "auto",
            paddingRight: 2,
            "&::-webkit-scrollbar": {
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.2)",
              borderRadius: "3px",
            },
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Dashboard tabs"
            sx={{
              minHeight: "32px",
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root": {
                marginRight: "6px",
                "&:last-child": {
                  marginRight: 0,
                },
              },
            }}
          >
            {getAvailableTabs().map((tab, index) => (
              <PlainTab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            overflowX: "auto",
            overflowY: "hidden",
            paddingLeft: 2,
            "&::-webkit-scrollbar": {
              height: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.2)",
              borderRadius: "3px",
            },
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              flexWrap: "nowrap",
              minWidth: "fit-content",
            }}
          >
            <Select
              style={{ fontFamily: "Inter" }}
              value={selectedInterval}
              onChange={handleIntervalChange}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </Select>
            <DatePicker
              value={dateFilter}
              className={styles.control}
              placeholder="Select a date..."
              onSelectDate={(date: Date | null | undefined) =>
                setDateFilter(date || null)
              }
              style={{ fontFamily: "Inter" }}
            />
            <Select
              appearance="outline"
              value={unit}
              onChange={handleUnitChange}
              style={{ fontFamily: "Inter" }}
            >
              <option value="liters">Liters</option>
              <option value="gallons">Gallons</option>
            </Select>
            <StyledButton
              onClick={toggleReportDrawer(true)}
              icon={<DataBarVerticalAscendingFilled />}
              iconColor={theme.palette.primary.main}
            >
              Generate Report
            </StyledButton>
            <OverlayDrawer
              position={"end"}
              open={isReportDrawerOpen}
              onOpenChange={(
                event: React.SyntheticEvent,
                { open }: { open: boolean }
              ) => setIsReportDrawerOpen(open)}
            >
              <DrawerHeader>
                <DrawerHeaderTitle
                  action={
                    <Button
                      appearance="subtle"
                      aria-label="Close"
                      icon={<Dismiss24Regular />}
                      onClick={() => setIsReportDrawerOpen(false)}
                    />
                  }
                >
                  Generate Report
                </DrawerHeaderTitle>
              </DrawerHeader>

              <DrawerBody>
                <p className={styles.sustainabilityIconTextAlign}>
                  <TreeEvergreen20Filled className={styles.treeIcon} />
                  Sustainability Report
                </p>
                <Button appearance="subtle" onClick={() => {}}>
                  Sustainability Report (PDF)
                </Button>
                <Button appearance="subtle" onClick={() => {}}>
                  Sustainability Report (CSV)
                </Button>
                <Divider className={styles.drawerDividerMargin} />
                <Select
                  className={styles.drawerInputs}
                  appearance="outline"
                  value={selectedReportInterval}
                  onChange={handleReportIntervalChange}
                >
                  <option value="daily">Daily Report</option>
                  <option value="weekly">Weekly Report</option>
                  <option value="monthly">Monthly Report</option>
                </Select>
                <Button appearance="subtle" onClick={() => {}}>
                  {selectedReportInterval.charAt(0).toUpperCase() +
                    selectedReportInterval.slice(1)}{" "}
                  Report (PDF)
                </Button>
                <Button appearance="subtle" onClick={() => {}}>
                  {selectedReportInterval.charAt(0).toUpperCase() +
                    selectedReportInterval.slice(1)}{" "}
                  Report (CSV)
                </Button>
              </DrawerBody>
            </OverlayDrawer>
            <StyledIconButton onClick={refreshPage}>
              <ArrowClockwiseFilled />
            </StyledIconButton>
          </Stack>
        </Box>
      </Box>
      {viewMode === "icleanlines" && (
        <Grid container spacing={1.25} alignItems="flex-start">
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={1.25}
              sx={{
                display: "flex",
                alignItems: "stretch",
              }}
            >
              <Grid item xs={12} lg={6} sx={{ display: "flex" }}>
                <AutoCycle />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ display: "flex" }}>
                <TotalUsageCard
                  selectedDevices={selectedIcleanLinesDevices}
                  isGallons={isGallons}
                  color={theme.palette.success.main}
                />
              </Grid>
              <Grid item xs={12}>
                <ICleanLinesMetricGraph
                  selectedDevices={selectedIcleanLinesDevices}
                />
              </Grid>
              <Grid item xs={12}>
                <TimeDynamicGraph
                  selectedDate={selectedDate}
                  selectedDevices={selectedIcleanLinesDevices}
                  isGallons={isGallons}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1.25}>
              <Grid item xs={12}>
                <IcleanLinesDeviceTable
                  devices={iCleanLinesDevices}
                  dateFilter={dateFilter}
                  intervalFilter={intervalFilter}
                  isGallons={isGallons}
                  setIsGallons={() => setIsGallons(!isGallons)}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <MapView center={center} zoom={zoom} markers={markers} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {viewMode === "icleanlinesOld" && <OldICleanLinesDashboard />}
    </Box>
  );
};

export default ICleanLinesDashboard;
