// Utility type for deep partial
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export interface DistributorInformation {
  companyName: string;
  accountNumber: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  region: string;
  zip: string;
  submittedByDistributor?: boolean;
}

export interface ProductSiteInformation {
  serialNumber: string;
  deviceModel: string;
  purchaseDate: Date;
  company: string;
  address: string;
  city: string;
  country: string;
  region: string;
  zip: string;
}

export interface FaultDescription {
  returnReason: string;
  warrantyType: "IN" | "OUT";
  creditStatus: "YES" | "NO";
  creditAmount: number;
  replacementUnit: "YES" | "NO";
  defectType: string;
  description: string;
  photo: string;
  errorCode: LotusProErrors | iCleanMiniErrors;
}

export interface CustomerFaultDescription {
  returnReason: string;
  defectType: string;
  description: string;
  photo?: string;
  warrantyType?: "IN" | "OUT";
  creditStatus?: "YES" | "NO";
  creditAmount?: number;
  replacementUnit?: "YES" | "NO";
  errorCode?: LotusProErrors | iCleanMiniErrors;
}
// Enum for LotusPro Errors
export enum LotusProErrors {
  BBHD = "Broken Bulkhead",
  CBPD = "Control Board Power Disconnected",
  CELM = "Cover Electronics Missing",
  CFOG = "Check Valve on OffGas failed",
  COZD_OGCR = "Cracked Ozone Generator",
  CSNZ = "Carulite Stack not in Position",
  CVF = "Check Valve Failed",
  DBNL = "Display Board Faulty - No Lights",
  DMGH = "Damaged Dispenser Housing",
  DND = "Does not Dispense",
  DSIB = "Dosing Solenoid Broken",
  FBCB = "Fuse Blow on Control Board",
  FJIL = "FloJet is Leaking",
  FLTD = "Float Damaged",
  FMBN = "Flow Meter is Broken",
  HCBC_HIC = "Heavy Internal Corrosion",
  HCDR_LPDR = "Lotus PRO Damaged in Return",
  HCDT_LPDT = "Lotus PRO Damaged in Tranit",
  HDCB = "Desiccant Hose Broken",
  HTS = "Internal Hoses too Short",
  HVLO = "High Voltage Trans. Leaking Oil",
  IWFP = "Intermittent Water flow & Power",
  LBKH_LBH = "Leaking from Bulkhead",
  LOFL = "Low Flow Light",
  LOOP = "Low Ozone Output",
  MCPH = "Melted Components/Plastic",
  NAPU = "Non-Approved Part Used",
  NODF = "No Defect Found",
  NRDD = "No Repair Documented",
  NRDF = "Lotus PRO will not read Desiccant",
  OGBG = "Off Gas is Bubbling",
  OGBT = "Ozone Generator is Burnt",
  OGFD = "Off Gas Failed",
  OSSR = "ORP Sensor Screw Rusted",
  PBNS_PBSS = "Power Button Stuck/Seized",
  PHFO = "Pinched Hose flooded Ozone Gen",
  RCIB = "Ribbon Cable Bent",
  QCRB = "Red/Blue Connector Damaged",
  QCBW = "White/Black Connector Damaged",
  RPRT = "Repair and Return to Customer",
  SCRP = "Not Repairable / Scrapped for Parts",
  SCRS = "System Charging / Red Service",
  SLIR = "Service Light Intermittent Red",
  SLSR = "Service Light Solid Red",
  SOSM = "Strong Ozone Smell",
  TERS = "Upgrade and Return to Stock",
  TFFB = "Transformer Fell off Board",
  TGFC = "Tripping GFCI Box",
  TNBR = "Try and Buy Return to Stock",
  UHBS = "Unit has Burning Smell",
  URWW = "Unit Runs Without Water",
  UVSK = "Unit Vibrating/Shaking",
  UWNP = "Unit will not Power Up",
  VSA = "Vacuum Switch Adjusted",
  VSNC = "Vacuum Switch Not Connected",
  VSRS = "Vacuum Switch Rusted",
  WLCU = "Water Leaking - Center",
  WLLU = "Water Leaking - Left Side",
  WLRU = "Water Leaking - Right Side",
  WOCV = "Water in Ozone Gen. Check Vlv Failed",
  WSEC = "Water Solenoid Elbow Cracked",
  WSGN = "Water Solenoid Grinding Noise",
  WSSO = "Water Solenoid Stuck Open",
  WSSS = "Water Solenoid Stuck Shut",
  CBPE = "Control Board Programming Error",
  FCPE = "Front Cover Programming Error",
  HIPR = "High Priority",
}

// Enum for iCleanMini Errors
export enum iCleanMiniErrors {
  ACAB = "Aerator Cracked at Bottom",
  APPC = "Adaptor Pin Pulled Out of Cord",
  ASFO = "Aerator Screen Fell Out",
  BDIR = "Body Disintegrating into Reservoir",
  BFLD = "Lithium Battery Failed",
  BKSH = "Broken Secondary Housing",
  CARB = "Cartridge Red out of Box",
  CBFL = "Circuit Board Failed",
  CCBL = "Cell Cover Blocked",
  CPIS = "Charging Port is Loose",
  CTEE = "Cartridge Expired Early",
  DOBW = "Decal on Button Worn Off",
  DPMP = "Defective Pump",
  FBWP = "Flashing Blue or Wont Pump",
  LBRS = "LED Blue and RED during Spraying",
  LDNB = "LED does not turn blue in Charging",
  LEDN = "LED Does not Illuminate",
  LFNZ = "Leaking from Nozzle",
  LOOP = "Low Ozone Output",
  MPRT = "Melted Charging Port",
  NAPU = "Non-Approved Part Used",
  NODF = "No Defect Found",
  NRDD = "No Repair Documented",
  PBPL = "Power Button Peeling",
  RBB = "Reservoir Bib Broken W/N stay on",
  RNOP = "Reservoir Not Fitting Properly",
  RPRT = "Repair and Return to Customer",
  RTCK = "Reservoir Tank Cracked",
  SCRP = "Not Repairable / Scrapped for Parts",
  TERS = "Upgrade and Return to Stock",
  UWNC = "iClean will not Charge",
  UWNS = "Unit will not Spray/Dribbling",
  WLCP = "Water Leaking from Charging Port",
  RRBN = "Refurb Received / not Brand New",
  DNPM = "Device not Primed upon Receipt",
  HIPR = "High Priority",
}

export interface ProductWarranty {
  _id: string;
  distributorInformation: DistributorInformation;
  productSiteInformation: ProductSiteInformation;
  faultDescription: FaultDescription;
  status:
  | "Initial review"
  | "Technical assessment"
  | "Approved"
  | "Received"
  | "Closed"
  | "Rejected";
  comment?: string;
  createdAt: string;
  updatedAt: string;
  isCreditValid: boolean;
}

export interface CreateProductWarranty {
  distributorInformation: DistributorInformation;
  productSiteInformation: ProductSiteInformation;
  faultDescription: FaultDescription;
  status:
  | "Initial review"
  | "Technical assessment"
  | "Approved"
  | "Closed"
  | "Rejected";
}

export interface CreateCustomerProductWarranty {
  distributorInformation: DistributorInformation;
  productSiteInformation: ProductSiteInformation;
  faultDescription: CustomerFaultDescription;
  status:
  | "Initial review"
  | "Technical assessment"
  | "Approved"
  | "Closed"
  | "Rejected";
}
