import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import logoWhite from "../assets/images/tersanowhite.png";
import { DeviceData } from "../common/types/DeviceTypes";
import Sidebar from "../components/Sidebar/Sidebar";
import ICleanLinesSidebar from "../components/Sidebar/ICleanLinesSidebar";
import All from "../components/Product/All";
import {
  drawerWidth,
  miniDrawerWidth,
  SelectedPortalType,
} from "../common/types/constants";
import SearchBar from "../components/Header/SearchBar";
import Notification from "../components/Header/Notification";
import Profile from "../components/Header/Profile";
import SwitchProfile from "../components/Header/SwitchPortal";
import { DeviceMonitoring } from "./DeviceMonitoring";
import ICleanLinesDashboard from "../iCleanLines/pages/dashboard/ICleanLinesDashboard";
import ICleanDashboard from "../components/Product/CleanDashboard";
import AddAccountPage from "./AddAccount";
import CustomerTable from "../components/Customer/ManageCustomerTable";
import useDeviceStore from "../store/useDeviceStore";
import ManageDeviceTests from "../components/Tester/ManageDeviceTests";
import useAuthStore from "../store/authStore";
import DistributorTable from "../components/Distributor/ManageDistributorTable";
import ManageUsers from "../components/Users/ManageUsers";
import CustomerSupport from "../components/CustomerSupport/CustomerSupport";
import DeviceDetailsNew from "./DeviceDetailsNew";
import { ProfilePage } from "./ProfilePage";
import { HelpDesk } from "../components/HelpDesk/HelpDesk";
import HomePage from "./Home";
import DistributorTableV2 from "../components/Distributor/DistributorTable";
import WarrantyClaims from "../components/CustomerService/WarrantyClaims";
import ServiceTools from "../components/CustomerService/Tools";
import { ServiceReports } from "../components/CustomerService/Reports";
import ServiceSummary from "../components/CustomerService/ServiceSummary";
import DeviceArrivals from "../components/CustomerService/DeviceArrivals";
import ManageProdDeviceTests from "../components/DeviceTest/ManageDeviceTests";
import CreateWarrantyPage from "./CreateNewWarranty";
import ProductWarranties from "../components/ProductWarranty/ProductWarranties";
import InstallationWizard from "./InstallationWizard";
import DeviceReports from "../components/Reports/DeviceReports";
import CustomerReports from "../components/Reports/CustomerReports";
import CreateDeviceReturn from "../components/Production/CreateDeviceReturn";
import ReactGA from "react-ga4";
import ManageProdDeviceReworks from "../components/DeviceRework/ManageDeviceRework";
import ChangeRequestAdminPanel from "../components/Admin/ChangeRequestAdminPanel";
import ManageInstallers from "../components/Distributor/ManageInstallerTable";
import CustomerDetails from "../components/Customer/CustomerDetails";
import NotificationSettings from "../components/Admin/NotificationSettings";
import InventoryPartsTable from "../components/Production/InventoryPartsTable";
import AllNotifications from "../components/Notifications/AllNotifications";
import DistributorSalesReporting from "../components/Distributor/DistributorSalesReporting";
import InventoryReports from "../components/Reports/InventoryReports";
import AddInventory from "../components/Production/AddInventory";
import { LineHorizontal320Regular } from "@fluentui/react-icons";
import { fetchDeviceDetails } from "../services/device.service";

const MainLayout: React.FC = () => {
  const { allDevices, fetchDevices } = useDeviceStore();
  const { user, userRole } = useAuthStore();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPortal, setSelectedPortal] = useState<SelectedPortalType>(
    (localStorage.getItem("selectedPortal") as SelectedPortalType) ||
    "Lotus-Pro"
  );
  const [selectedDevice, setSelectedDevice] = useState<DeviceData | null>(null);

  const handlePortalChange = (portal: SelectedPortalType) => {
    setSelectedPortal(portal);
    localStorage.setItem("selectedPortal", portal);
    switch (portal) {
      case "Lotus-Pro":
        setActiveTab("iClean");
        break;
      case "iCleanLines":
        setActiveTab("iCleanLines");
        break;
      default:
        setActiveTab("iClean");
        break;
    }
  };

  useEffect(() => {
    fetchDevices(); // Fetch devices when the app loads
  }, [fetchDevices]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDevices();
    }, 7200000); // 2 hours in milliseconds

    return () => clearInterval(interval);
  }, [fetchDevices]);

  const handleDeviceSelect = async (device: DeviceData | null) => {
    if (
      !device ||
      !device.deviceInformation ||
      !device.deviceInformation.deviceId
    ) {
      console.log("Invalid device data");
      return;
    }
    const deviceId = device.deviceInformation.deviceId;
    setSelectedDevice(device);
    localStorage.setItem("selectedDeviceId", deviceId);
    setActiveTab("deviceDetails");
  };

  const handleCustomerSelect = async (customer: any) => {
    setSelectedCustomer(customer);
    localStorage.setItem("selectedCustomer", JSON.stringify(customer));
    setActiveTab("customerDetails");
  };

  useEffect(() => {
    const savedCustomer = localStorage.getItem("selectedCustomer");
    if (savedCustomer) {
      setSelectedCustomer(JSON.parse(savedCustomer)); // Restore selected customer
    }
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      localStorage.setItem(
        "selectedCustomer",
        JSON.stringify(selectedCustomer)
      );
    }
  }, [selectedCustomer]);

  // Define a function to get the initial tab based on the user's role
  const getInitialTab = () => {
    if (userRole) {
      switch (userRole) {
        case "SuperAdmin":
        case "Admin":
          return "iClean";
        case "Customer":
        case "GroupCustomer":
        case "GlobalAccount":
        case "NationalAccount":
        case "SubCustomer":
        case "Distributor":
        case "SubDistributor":
        case "Installer":
          return "home";
        case "Tester":
          return "deviceTests";
        case "Service":
          return "productWarranties";
        case "Production":
          return "createDeviceReturn";
        case "Inventory":
          return "inventoryParts";
        case "Supervisor":
          return "inventoryParts";
        default:
          return "iClean";
      }
    }
    return "iClean";
  };

  const [activeTab, setActiveTab] = useState<string>(
    localStorage.getItem("activeTab") || getInitialTab()
  );
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    if (activeTab !== "deviceDetails") {
      localStorage.setItem("selectedDeviceId", "");
    }
  }, [activeTab]);

  // Add this useEffect so the device details page gets data that specific page gets refreshed
  useEffect(() => {
    const getDeviceDetails = async () => {
      const deviceId = localStorage.getItem("selectedDeviceId");
      if (deviceId) {
        const device = await fetchDeviceDetails(deviceId);
        setSelectedDevice(device);
      }
    };

    getDeviceDetails();
  }, []);

  const handleTabChange = (tabName: string) => {
    ReactGA.send({
      hitType: "pageview",
      page: "/dashboard/" + tabName, // Customize the path as needed
      title: tabName,
    });
    console.log("GA4 Tracking Tab View:", "/dashboard/" + tabName);
    setActiveTab(tabName);
  };

  // Determine the portal title based on the user's role
  const getPortalTitle = () => {
    if (userRole) {
      switch (userRole) {
        case "SuperAdmin":
          return "Super Admin Portal";
        case "Admin":
          return "Admin Portal";
        case "Customer":
        case "SubCustomer":
          return "Customer Portal";
        case "GlobalAccount":
          return "Global Account";
        case "NationalAccount":
          return "National Account";
        case "GroupCustomer":
          return "Company Portal";
        case "Distributor":
          return "Distributor Portal";
        case "SubDistributor":
          return "Sub-Distributor Portal";
        case "Installer":
          return "Installer Portal";
        case "Tester":
          return "Tester Portal";
        case "Production":
          return "Production Portal";
        case "Service":
          return "Service Portal";
        case "Viewer":
          return "Viewer Portal";
        case "Group":
          return "Group Portal";
        case "Inventory":
          return "Inventory Portal";
        case "Supervisor":
          return "Supervisor Portal";
        default:
          return "Dashboard";
      }
    }
    return "Dashboard";
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile screen size

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* AppBar should always be fixed at the top */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          "&.MuiAppBar-root": {
            background:
              "linear-gradient(45deg, rgba(30, 58, 138, 1) 30%, rgba(30, 58, 138, 0.9) 90%)",
          },
          "&.MuiPaper-root": {
            height: "48px", // Height of the AppBar
            minHeight: "48px",
            justifyContent: "center",
          },
          "&.MuiBox-root": {
            height: "48px", // Height of the header
          },
        }}
      >
        {/* AppBar contents... */}
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
            sx={{
              transition: (theme) => theme.transitions.create("width"),
              animation: "ease-out 0.2s fade-in",
            }}
          >
            {!isMobile && (
              <Box
                sx={{
                  mx: 8.25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!isMobile && (
                  <img src={logoWhite} alt="tersano-white" height={16} />
                )}
              </Box>
            )}
            <Box
              onClick={handleDrawerToggle}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                color: "white",
                p: 1.75,
                ":hover": {
                  color: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <LineHorizontal320Regular />
            </Box>
            {isMobile && (
              <Box mr={3}>
                <img src={logoWhite} alt="logo" height={14} />
              </Box>
            )}
          </Box>
          {/* Right side of the header */}
          <Box
            justifyContent="center"
            display="flex"
            alignContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Box>
              {" "}
              <SearchBar
                devices={allDevices}
                onDeviceSelect={handleDeviceSelect}
              />
            </Box>
            <Box>
              <Notification
                onChangeTab={handleTabChange}
                email={user?.email ?? ""}
              />
            </Box>
            {userRole && userRole === "Admin" && (
              <Box>
                <SwitchProfile
                  onChangePortal={handlePortalChange}
                  portalSelected={selectedPortal}
                  isMobile={isMobile}
                />
              </Box>
            )}
            {/* Profile icon */}
            <Profile onChangeTab={handleTabChange} />{" "}
          </Box>
        </Box>
      </AppBar>

      {/* Sidebar Drawer */}
      {userRole && userRole !== "Viewer" && (
        <Drawer
          variant={isMobile ? "temporary" : "permanent"} // Use "temporary" on mobile, "permanent" on desktop
          open={drawerOpen || !isMobile} // Open drawer on mobile if drawerOpen is true, or always open on desktop
          onClose={handleDrawerToggle} // Close drawer on mobile when user clicks outside or the button
          sx={{
            width: isMobile
              ? drawerWidth
              : drawerOpen
                ? drawerWidth
                : miniDrawerWidth, // Full width on mobile, mini/full on desktop
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: isMobile
                ? drawerWidth
                : drawerOpen
                  ? drawerWidth
                  : miniDrawerWidth,
              boxSizing: "border-box",
              position: "fixed",
              height: "calc(100% - 48px)", // Adjust height if AppBar height is different
              top: "48px", // Height of the AppBar
              transition: "width 0.3s ease-in-out", // Smooth transition when opening/closing
              overflowX: "hidden",
            },
          }}
        >
          <Box
            sx={{
              p: 1,
              mt: 2,
              mx: drawerOpen ? 2 : 1,
              ml: drawerOpen ? 2 : 2,
              backgroundColor: "#f5f5f5",
              boxShadow: "none",
              border: "1px solid #d1d1d1",
              borderRadius: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#424242",
                fontSize: "14px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              {
                drawerOpen
                  ? getPortalTitle() // Full title if drawer is open
                  : getPortalTitle()
                    .split(" ") // Split the title into words
                    .map((word) => word.charAt(0)) // Get the first letter of each word
                    .join("") // Join the first letters
              }
            </Typography>
          </Box>

          {/* Conditionally render different sidebars */}
          {selectedPortal === "Lotus-Pro" ? (
            <Sidebar
              activeTab={activeTab}
              drawerOpen={drawerOpen}
              onTabClick={(tabName) => {
                handleTabChange(tabName);
                localStorage.setItem("activeTab", tabName);
              }}
            />
          ) : (
            <ICleanLinesSidebar
              activeTab={activeTab}
              drawerOpen={drawerOpen}
              onTabClick={(tabName) => {
                handleTabChange(tabName);
                localStorage.setItem("activeTab", tabName);
              }}
            />
          )}
        </Drawer>
      )}

      {/* Main content, it should adjust its width depending on the sidebar state */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 0.5,
          py: 0.5,
          transition: (theme) =>
            theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`, // Width is dynamic based on the sidebar state
          marginTop: "48px", // Height of the AppBar
          overflow: "auto",
        }}
      >
        {/* =============== Lotus Pro =============== */}
        {activeTab === "home" && (
          <HomePage
            devices={allDevices.slice(0, 2)}
            onTabChange={handleTabChange}
            onDeviceSelect={handleDeviceSelect}
          />
        )}
        {activeTab === "iClean" && <ICleanDashboard />}
        {activeTab === "install" && <InstallationWizard devices={allDevices} />}
        {activeTab === "all" && <All devices={allDevices} />}
        {activeTab === "devices" && (
          <DeviceMonitoring
            onDeviceSelect={handleDeviceSelect}
            devices={allDevices}
          />
        )}
        {activeTab === "addAccount" && <AddAccountPage />}
        {activeTab === "manageCustomers" && (
          <CustomerTable onCustomerSelect={handleCustomerSelect} />
        )}
        {activeTab === "myDevices" && (
          <DeviceMonitoring
            onDeviceSelect={handleDeviceSelect}
            devices={allDevices}
          />
        )}
        {activeTab === "customerSupport" && <CustomerSupport />}
        {activeTab === "distributorSupport" && (
          <HelpDesk devices={allDevices} />
        )}
        {activeTab === "deviceDetails" && selectedDevice && (
          <DeviceDetailsNew
            device={selectedDevice}
            onBack={() => {
              setActiveTab(userRole === "Customer" ? "myDevices" : "devices");
            }}
          />
        )}
        {activeTab === "customerDetails" && selectedCustomer && (
          <CustomerDetails
            customer={selectedCustomer}
            onBack={() => {
              setActiveTab("manageCustomers");
            }}
          />
        )}
        {activeTab === "profile" && <ProfilePage />}
        {activeTab === "deviceReports" && <DeviceReports />}
        {activeTab === "customerReports" && <CustomerReports />}

        {/* ================= ICleanLines ================= */}
        {activeTab === "iCleanLines" && <ICleanLinesDashboard />}

        {/* =============== Internal Portals =============== */}
        {activeTab === "createDeviceReturn" && <CreateDeviceReturn />}
        {activeTab === "deviceReworks" && <ManageProdDeviceReworks />}
        {activeTab === "inventoryParts" && <InventoryPartsTable />}
        {activeTab === "inventoryReports" && <InventoryReports />}
        {activeTab === "addRecoveredParts" && <AddInventory />}
        {activeTab === "manageTests" && <ManageDeviceTests />}
        {activeTab === "manageDistributors" && <DistributorTableV2 />}
        {activeTab === "manageInstallers" && <ManageInstallers />}
        {activeTab === "manageMainDistributors" && <DistributorTable />}
        {activeTab === "manageUsers" && <ManageUsers />}
        {activeTab === "warrantyClaims" && (
          <WarrantyClaims devices={allDevices} />
        )}
        {activeTab === "productWarranties" && <ProductWarranties />}
        {activeTab === "deviceArrivals" && (
          <DeviceArrivals devices={allDevices} />
        )}
        {activeTab === "serviceTools" && <ServiceTools devices={allDevices} />}

        {activeTab === "serviceReports" && (
          <ServiceReports devices={allDevices} />
        )}
        {activeTab === "serviceSummary" && <ServiceSummary />}
        {activeTab === "deviceTests" && <ManageProdDeviceTests />}
        {activeTab === "createWarrantyClaim" && <CreateWarrantyPage />}
        {activeTab === "changeRequests" && <ChangeRequestAdminPanel />}
        {activeTab === "notificationSettings" && (
          <NotificationSettings devices={allDevices} />
        )}
        {activeTab === "notifications" && (
          <AllNotifications email={user?.email ?? ""} />
        )}
        {activeTab === "retentionSheet" && <DistributorSalesReporting />}
      </Box>
    </Box>
  );
};

export default MainLayout;
